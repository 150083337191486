import React from "react";
import "./GoogleLoginButton.css";
import Google from "./assets/google.svg";
import { SVG } from ".";
import { Text } from "./Text.js";

export const GoogleLoginButton = ({ text, onClick }) => {

  return (
    <button onClick={onClick} className="google-login-button">
      <SVG src={Google} />
      <Text text={text} className="google-login-button-text" />
    </button>
  );
};
