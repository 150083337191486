import firebaseConfig from "../firebaseConfig.js";
import { initializeApp, getApps, getApp } from "firebase/app";

const getFirebaseApp = () => {
    if (getApps().length === 0) {
        return initializeApp(firebaseConfig);
    } else {
        return getApp();
    }
}

export default getFirebaseApp;