import { GuidesHeader, Button, Heading3, Heading4, Table } from "../components";
import "./GuidesGroup.css";
import Plus from "../components/assets/plus.svg";
import nameOnBoardingLogoSVG from "./assets/nameOnBoardingLogoSVG.svg";
import { SVG } from "./SVG";
import { Heading5 } from "./Heading5";
import nameOnboardingProfile from "./assets/nameOnBoardingProfile.svg";
import { Input } from "./Input";
import { Text } from "./Text";
import guideBlankStateSVG from "./assets/guideBlankStateSVG.svg";
import trashIcon from "./assets/trash.svg";
import plusIcon from "./assets/plus.svg";
import trashRedIcon from './assets/trash-red.svg';
import CloseButton from './assets/close-button.svg';
import userHexagonIcon from './assets/user-hexagon.svg';
import calendarIcon from './assets/calendar.svg';
import plusGreenIcon from './assets/plus-green.svg';
import GuidesSectionHeader from "./GuidesSectionHeader";
import CircleProgress from "./CircleProgress";
import { Heading6 } from "./Heading6";
import { useState } from "react";
import Checkbox from "./Checkbox";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import Select from 'react-select'

const readableTime = (previousTimeInSeconds, currentTimeInSeconds) => {
    const timeDifference = currentTimeInSeconds - previousTimeInSeconds;
    if (timeDifference < 3600) {
        const minutes = Math.round(timeDifference / 60);
        if (minutes < 2) {
            return "1 minute ago";
        } else {
            return minutes + " minutes ago";
        }
    } else if (timeDifference < 86400) {
        const hours = Math.round(timeDifference / 3600);
        if (hours < 2) {
            return "1 hour ago";
        } else {
            return hours + " hours ago";
        }
    } else if (timeDifference < 172800) {
        return 'Yesterday';
    } else if (timeDifference < 604800) {
        const days = Math.round(timeDifference / 86400);
        return days + ' days ago';
    } else {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const previousDate = new Date(previousTimeInSeconds * 1000);
        return previousDate.toLocaleDateString('default', options);
    }
}

const formattedTime = (timeInSeconds) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' };
    const date = new Date(timeInSeconds * 1000);
    return date.toLocaleDateString('default', options);
}

const dueDateFormattedTime = (dueDate) => {
    const months = [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "Decemberr"
    ];

    const dateString = dueDate.day + " " + months[dueDate.month] + " " + dueDate.year;
    return dateString;

}

const homeHeadings = [
    { label: 'Guides', value: 'guides' },
    { label: 'Sessions', value: 'sessions' },
    { label: 'Contacts', value: 'contacts' },
    { label: 'Accounts', value: 'accounts' },
    { label: 'ToDo\'s', value: 'todos' },
];

export const GuidesGroup = (props) => {
    const currentTimeInSeconds = Math.round(Date.now() / 1000);
    
    const getGuidesGroup = ({ fullName, guides, pageStatus}) => {
        if (!fullName) {
            return (
                <div className="name-onboarding-container">
                    <div className="name-onboarding-header">
                        <SVG src={nameOnBoardingLogoSVG} className="guides-name-onboarding-logo-svg" />
                    </div>
                    <div className="name-onboarding-body">
                        <div className="name-onboarding-body-text">
                            <Heading4 text="Thanks for jumping into SlideGuide" className="name-onboarding-body-textone" />
                            <Heading5 text="We are glad you are here" className="name-onboarding-body-texttwo" />
                        </div>

                        <div className="name-onboarding-input">
                            <SVG src={nameOnboardingProfile} />
                            <Input type="email" placeholder="How should we call you?" />

                        </div>
                    </div>
                </div>
            );
        } else if (pageStatus && pageStatus === 'sessions' ) {
            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active={pageStatus} onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            dataSource={props.sessions.map((session) => ({
                                ...session,
                                // account: props.accounts.find(a => a.accountId === session.sessionId)
                                //     ? props.accounts.find(a => a.accountId === session.sessionId).name
                                //     : '', 
                            }))}
                            columns={[
                                {
                                    key: 'guideName',
                                    title: 'Guide name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                // {
                                //     key: 'account',
                                //     title: 'Account',
                                //     render: (text, data) => <Heading5 text={text} />,
                                // },
                                {
                                    key: 'createdDate',
                                    title: 'Date',
                                    render: (text, data) => <Text text={readableTime(data.createdDate.seconds, currentTimeInSeconds)} />,
                                },
                                // {
                                //     key: 'time',
                                //     title: 'Time',
                                //     render: (text, data) => <Text text={text} />,
                                // },
                                {
                                    key: 'attendees',
                                    title: 'Who Attended',
                                    render: (text, data) => <Heading5 text={
                                        data.attendees && data.attendees.length > 0
                                            ? `${data.attendees[0]}${data.attendees.length > 1 ? ' +' + (data.attendees.length -1) + ' others' : ''}`
                                            : ''
                                        } />,
                                },
                            ]}
                        />
                    </div>
                </div>
            );    
        } else if (pageStatus && pageStatus === 'sessionDetails' ) {
            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="session-details-header">
                        <div className="session-details-header-title">
                            <Heading3 text={props.session && props.session.guideName} />
                            <span className="session-details-header-separator"></span>
                            <Heading3 text={props.session && props.session.accountName} />
                        </div>

                        <div className="session-details-header-title-details-container">
                            <div className="session-details-header-title-details-box">
                                <div className="session-details-header-title-details-box-label">
                                    <SVG src={userHexagonIcon} size={21} />
                                    <Text text="Owner" />
                                </div>

                                <Heading5 text={props.email} />
                            </div>

                            <div className="session-details-header-title-details-box">
                                <div className="session-details-header-title-details-box-label">
                                    <SVG src={calendarIcon} size={21} />
                                    <Text text="Created on" />
                                </div>

                                <Heading5 text={formattedTime(props.session && props.session.createdDate.seconds)} />
                            </div>
                        </div>
                    </div>

                    <Table
                        className="session-details-table"
                        dataSource={[{
                            attendees: props.session.attendees,
                            notes: props.session.notes,
                            todos: props.session.todos,
                        }]}
                        columns={[
                            {
                                key: 'attendees',
                                title: 'Who Attended',
                                cellClassName: 'session-details-table-attendees-cell',
                                render: (text, data) => (
                                    <div className="session-details-table-attendees">
                                        {data.attendees.map(a => (
                                            <Heading5 text={a} />
                                        ))}
                                    </div>
                                ),
                            },
                            {
                                key: 'notes',
                                title: 'Session notes',
                                cellClassName: 'session-details-table-notes-cell',
                                render: (text, data) => <Text text={text} className="session-details-table-notes" />,
                            },
                            {
                                key: 'todos',
                                title: 'To Do\'s',
                                cellClassName: 'session-details-table-todos-cell',
                                render: (text, data) => (
                                    <div className="session-details-table-todos">
                                        {data.todos.map(t => (
                                            <Heading5 text={t} />
                                        ))}
                                    </div>
                                ),
                            }
                        ]}
                    />
                </div>
            );    
        } else if (pageStatus && pageStatus === 'contacts' ) {
            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active={pageStatus} onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            dataSource={props.contacts}
                            columns={[
                                {
                                    key: 'fullName',
                                    title: 'Name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'email',
                                    title: 'Email',
                                    render: (text, data) => <Text text={text} />,
                                },
                                {
                                    key: 'account',
                                    title: 'Account',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                // {
                                //     key: 'lastSeen',
                                //     title: 'Last Seen',
                                //     render: (text, data) => <Text text={text} />,
                                // },
                            ]}
                        />
                    </div>
                    
                    <Button className="create-guide-button" img={Plus} value="Create contact" onClick={() => props.guidesAPI().updatePageStatus('createContact')} />

                </div>
            );
        } else if (pageStatus && pageStatus === 'createContact' ) {

            const accountSelectOptions = props.accounts.map(account => {
                const label = account.name + " (" + account.domain + ")";
                const accountOption = {
                    label: label,
                    domain: account.domain,
                    accountId: account.accountId,
                    name: account.name
                };
                return accountOption
            });

            const CreateContactFloatingPane = (
                <div className="create-contact-overlay">
                    <div className="create-contact-right-pane">
                        <div className="create-contact-right-pane-header">
                            <Heading4 text="Add a New Contact" />
                            <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => props.guidesAPI().updatePageStatus("contacts")}/>
                        </div>
                        <div className="create-contact-body">
                            <div className="bordered-input-container">
                                <Heading6 text="Name" />
                                <div className="bordered-input">
                                    <Input placeholder={"Enter the contact's full name"} value={props.createContactName} onChange={e => props.guidesAPI().updateCreateContactName(e.target.value)} />
                                </div>
                            </div>

                            <div className="bordered-input-container">
                                <Heading6 text="Email" />
                                <div className="bordered-input">
                                    <Input placeholder={"Enter contact's email"} value={props.createContactEmail} onChange={e => props.guidesAPI().updateCreateContactEmail(e.target.value)} />
                                </div>
                            </div>

                            <div className="bordered-input-container">
                                <Heading6 text="Account" />
                                <div className="bordered-input">
                                    <Select options={accountSelectOptions} 
                                        onChange={(valueType, actionType) => {
                                            if (actionType.action === 'select-option') {
                                                props.guidesAPI().updateNewContactAccount(valueType);
                                            }}}/>
                                </div>
                            </div>
                        </div>

                        <Button img={plusIcon} value="Add a New Contact" onClick={props.guidesAPI().createContactOnClick} />
                    </div>
                </div>
            );
            

            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active="contacts" onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            dataSource={props.contacts}
                            columns={[
                                {
                                    key: 'fullName',
                                    title: 'Name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'email',
                                    title: 'Email',
                                    render: (text, data) => <Text text={text} />,
                                },
                                {
                                    key: 'account',
                                    title: 'Account',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                // {
                                //     key: 'lastSeen',
                                //     title: 'Last Seen',
                                //     render: (text, data) => <Text text={readableTime(data.lastSeen.seconds, currentTimeInSeconds)} />,
                                // },
                            ]}
                        />
                    </div>
                    {CreateContactFloatingPane}
                </div>
            );
        } else if (pageStatus && pageStatus === 'contactDetails' ) {
            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="contact-details-header">
                        <div className="contact-details-header-title">
                            <Heading3 text={`${props.contact.fullname} (${props.contact.email})`} />
                            <span className="contact-details-header-separator"></span>
                            <Heading3 text={props.contact && props.contact.account} />
                        </div>

                        <div className="contact-details-header-title-details-container">
                            <div className="contact-details-header-title-details-box">
                                <div className="contact-details-header-title-details-box-label">
                                    <SVG src={userHexagonIcon} size={21} />
                                    <Text text="Owner" />
                                </div>

                                <Heading5 text={props.contact && props.contact.email} />
                            </div>

                            <div className="contact-details-header-title-details-box">
                                <div className="contact-details-header-title-details-box-label">
                                    <SVG src={calendarIcon} size={21} />
                                    <Text text="Last Seen" />
                                </div>

                                <Heading5 text={props.contact && props.contact.lastSeen} />
                            </div>
                        </div>
                    </div>

                    <GuidesSectionHeader
                        className="contact-details-section-header"
                        headings={[
                            {
                                label: 'Sessions',
                                value: 'sessions',
                            },
                            {
                                label: 'ToDo\'s',
                                value: 'todos',
                            },
                        ]}
                        onTabClick={(tab) => console.log(tab)}
                        active={props.tabStatus}
                    />

                    {props.tabStatus === 'sessions' && (
                        <Table
                            className="contact-details-table"
                            dataSource={props.contact && props.contact.sessions}
                            columns={[
                                {
                                    key: 'guideName',
                                    title: 'File name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'createdDate',
                                    title: 'Date',
                                    render: (text, data) => <Text text={readableTime(data.createdDate.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'accountName',
                                    title: 'Account',
                                    render: (text, data) => <Text text={text} />,
                                },
                                {
                                    key: 'attendees',
                                    title: 'Who Attended',
                                    render: (text, data) => <Heading5 text={
                                        data.attendees && data.attendees.length > 0
                                            ? `${data.attendees[0]}${data.attendees.length > 1 ? ' +' + (data.attendees.length -1) + ' others' : ''}`
                                            : ''
                                        } />,
                                },
                            ]}
                        />
                    )}

                    {props.tabStatus === 'todos' && (
                        <Table
                            className="contact-details-table"
                            dataSource={props.contact && props.contact.todos}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'List name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'dueDate',
                                    title: 'Due Date',
                                    render: (text, data) => <Text text={readableTime(data.dueDate.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'lastUpdated',
                                    title: 'Last Update',
                                    render: (text, data) => <Text text={readableTime(data.lastUpdated.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'completed',
                                    title: 'Completed',
                                    render: (text, data) => {
                                        const progress = Math.round((data.taskStatus.complete/data.taskStatus.outstanding) * 100);
                                        return <div className="session-details-circle-progress-container">
                                            <Text className="session-details-circle-element " text={`${data.taskStatus.complete}/${data.taskStatus.outstanding}`} />
                                            <div className="session-details-circle-element">
                                                <CircleProgress value={progress} className="session-details-circular-progress" />
                                                <Heading6 text={`${progress}%`} />
                                            </div>
                                        </div>
                                    },
                                },
                            ]}
                        />
                    )}
                </div>
            );   
        } else if (pageStatus && pageStatus === 'accounts' ) {
            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active={pageStatus} onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            dataSource={props.accounts}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'Name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                // {
                                //     key: 'lastSession',
                                //     title: 'Last Session',
                                //     render: (text, data) => <Text text={text} />,
                                // },
                                // {
                                //     key: 'taskStatus',
                                //     title: 'To Do\'s',
                                //     render: (text, data) => <Text text={`${data.taskStatus.complete}/${data.taskStatus.outstanding}`} />,
                                // },
                                // {
                                //     key: 'status',
                                //     title: 'Status',
                                //     render: (text, data) => {
                                //         const progress = Math.round((data.taskStatus.complete/data.taskStatus.outstanding) * 100);
                                //         return <div className="table-circle-progress-container">
                                //             <CircleProgress value={progress} />
                                //             <Heading6 text={`${progress}%`} />
                                //         </div>
                                //     },
                                // }
                            ]}
                        />

                        <Button className="create-guide-button" img={Plus} value="Create account" onClick={() => props.guidesAPI().updatePageStatus('createAccount')} />

                    </div>
                </div>
            ); 
        } else if (pageStatus && pageStatus === 'createAccount' ) {
            const CreateAccountFloatingPane = (
                <div className="pane-overlay">
                    <div className="pane-right-pane">
                        <div className="pane-right-pane-header">
                            <Heading4 text="Add an account" />
                            <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => props.guidesAPI().updatePageStatus("accounts")} />
                        </div>
                        <div className="create-account-body">
                            <div className="bordered-input-container">
                                <Heading6 text="Name" />
                                <div className="bordered-input">
                                    <Input placeholder={"Enter the account name"} value={props.createAccountName} onChange={e => props.guidesAPI().updateCreateAccountName(e.target.value)} />
                                </div>
                            </div>

                            <div className="bordered-input-container">
                                <Heading6 text="Website" />
                                <div className="bordered-input">
                                    <Input placeholder={"Enter the account website"} value={props.createAccountWebsite} onChange={e => props.guidesAPI().updateCreateAccountWebsite(e.target.value)} />
                                </div>
                            </div>

                        </div>

                        <Button img={Plus} value="Create account" onClick={props.guidesAPI().createAccountOnClick} />

                    </div>
                </div>
            );

            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active="accounts" onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            dataSource={props.accounts}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'Name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                // {
                                //     key: 'owner',
                                //     title: 'Owner',
                                //     render: (text, data) => <Heading5 text={text} />,
                                // },
                                // {
                                //     key: 'lastSession',
                                //     title: 'Last Session',
                                //     render: (text, data) => <Text text={text} />,
                                // },
                                // {
                                //     key: 'taskStatus',
                                //     title: 'To Do\'s',
                                //     render: (text, data) => <Text text={`${data.taskStatus.complete}/${data.taskStatus.outstanding}`} />,
                                // },
                                // {
                                //     key: 'status',
                                //     title: 'Status',
                                //     render: (text, data) => {
                                //         const progress = Math.round((data.taskStatus.complete/data.taskStatus.outstanding) * 100);
                                //         return <div className="table-circle-progress-container">
                                //             <CircleProgress value={progress} />
                                //             <Heading6 text={`${progress}%`} />
                                //         </div>
                                //     },
                                // }
                            ]}
                        />
                    </div>
                    {CreateAccountFloatingPane}
                </div>
            );  
        } else if (pageStatus && pageStatus === 'accountDetails' ) {
            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="contact-details-header">
                        <div className="contact-details-header-title">
                            <Heading3 text={props.account.name} />
                        </div>

                        <div className="contact-details-header-title-details-container">
                            <div className="contact-details-header-title-details-box">
                                <div className="contact-details-header-title-details-box-label">
                                    <SVG src={userHexagonIcon} size={21} />
                                    <Text text="Owner" />
                                </div>

                                <Heading5 text={props.account && props.account.owner} />
                            </div>

                            <div className="contact-details-header-title-details-box">
                                <div className="contact-details-header-title-details-box-label">
                                    <SVG src={calendarIcon} size={21} />
                                    <Text text="Created on" />
                                </div>

                                <Heading5 text={formattedTime(props.account && props.account.createdDate.seconds)} />
                            </div>
                        </div>
                    </div>

                    <GuidesSectionHeader
                        className="contact-details-section-header"
                        headings={[
                            {
                                label: 'Sessions',
                                value: 'sessions',
                            },
                            {
                                label: 'ToDo\'s',
                                value: 'todos',
                            },
                            {
                                label: 'Contacts',
                                value: 'contacts',
                            },
                        ]}
                        onTabClick={(tab) => console.log(tab)}
                        active={props.tabStatus}
                    />

                    {props.tabStatus === 'sessions' && (
                        <Table
                            className="contact-details-table"
                            dataSource={props.account && props.account.sessions}
                            columns={[
                                {
                                    key: 'guideName',
                                    title: 'File name',
                                    cellClassName: 'contact-details-table-cell',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'createdDate',
                                    title: 'Date',
                                    cellClassName: 'contact-details-table-cell',
                                    render: (text, data) => <Text text={readableTime(data.createdDate.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'accountName',
                                    title: 'Account',
                                    cellClassName: 'contact-details-table-cell',
                                    render: (text, data) => <Text text={text} />,
                                },
                                {
                                    key: 'attendees',
                                    title: 'Who Attended',
                                    cellClassName: 'contact-details-table-attendees-cell',
                                    render: (text, data) => <Heading5 text={
                                        data.attendees && data.attendees.length > 0
                                            ? `${data.attendees[0]}${data.attendees.length > 1 ? ' +' + (data.attendees.length -1) + ' others' : ''}`
                                            : ''
                                        } />,
                                },
                            ]}
                        />
                    )}

                    {props.tabStatus === 'todos' && (
                        <Table
                            className="contact-details-table"
                            dataSource={props.account && props.account.todos}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'List name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'dueDate',
                                    title: 'Due Date',
                                    render: (text, data) => <Text text={readableTime(data.dueDate.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'lastUpdated',
                                    title: 'Last Update',
                                    render: (text, data) => <Text text={readableTime(data.lastUpdated.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'completed',
                                    title: 'Completed',
                                    render: (text, data) => {
                                        const progress = Math.round((data.taskStatus.complete/data.taskStatus.outstanding) * 100);
                                        return <div className="contact-details-circle-progress-container">
                                            <Text className="contact-details-circle-element " text={`${data.taskStatus.complete}/${data.taskStatus.outstanding}`} />
                                            <div className="contact-details-circle-element">
                                                <CircleProgress value={progress} className="contact-details-circular-progress" />
                                                <Heading6 text={`${progress}%`} />
                                            </div>
                                        </div>
                                    },
                                },
                            ]}
                        />
                    )}

                    {props.tabStatus === 'contacts' && (
                        <Table
                            className="contact-details-table"
                            dataSource={props.account && props.account.contacts}
                            columns={[
                                {
                                    key: 'fullname',
                                    title: 'Name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'email',
                                    title: 'Email',
                                    render: (text, data) => <Text text={text} />,
                                },
                                {
                                    key: 'account',
                                    title: 'Account',
                                    render: (text, data) => <Text text={text} />,
                                },
                                {
                                    key: 'lastSeen',
                                    title: 'Last Seen',
                                    render: (text, data) => <Text text={text} />,
                                },
                            ]}
                        />
                    )}          
                </div>
            );  
        } else if (pageStatus && pageStatus === 'todos' ) {
            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active="todos" onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            rowOnClick={props.guidesAPI().navigateToTodo}
                            dataSource={props.todos ?? []}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'To Do',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'account',
                                    title: 'Account',
                                    render: (text, data) => <Text text={text} />,
                                },
                                // {
                                //     key: 'contact',
                                //     title: 'Contact',
                                //     render: (text, data) => <Text text={text} />,
                                // },
                                {
                                    key: 'createdDate',
                                    title: 'Created',
                                    render: (text, data) => <Text text={readableTime(data.createdDate.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'lastUpdated',
                                    title: 'Updated',
                                    render: (text, data) => <Text text={readableTime(data.lastUpdated.seconds, currentTimeInSeconds)} />,
                                },
                                // {
                                //     key: 'completed',
                                //     title: 'Completed',
                                //     render: (text, data) => {
                                //         const progress = Math.round((data.taskStatus.complete/data.taskStatus.outstanding) * 100);
                                //         return <div className="contact-details-circle-progress-container">
                                //             <Text className="contact-details-circle-element " text={`${data.taskStatus.complete}/${data.taskStatus.outstanding}`} />
                                //             <div className="contact-details-circle-element">
                                //                 <CircleProgress value={progress} className="contact-details-circular-progress" />
                                //                 <Heading6 text={`${progress}%`} />
                                //             </div>
                                //         </div>
                                //     },
                                // },
                            ]}
                        />
                    </div>
                    <Button className="create-guide-button" img={Plus} value="Create To-Do list" onClick={() => props.guidesAPI().updatePageStatus('createTodo')} />
                </div>
            );
        } else if (pageStatus && pageStatus === 'createTodo' ) {

            const accountSelectOptions = props.accounts.map(account => {
                const label = account.name + " (" + account.domain + ")";
                const accountOption = {
                    label: label,
                    domain: account.domain,
                    accountId: account.accountId,
                    name: account.name
                };
                return accountOption
            });

            const CreateTodoFloatingPane = (
                    <div className="pane-overlay">
                        <div className="pane-right-pane">
                            <div className="pane-right-pane-header">
                                <Heading4 text="Create a To-Do list" />
                                <SVG className="pane-close-button" src={CloseButton} width="21" onClick={() => props.guidesAPI().updatePageStatus("todos")} />
                            </div>
                            <div className="create-account-body">
                                <div className="bordered-input-container">
                                    <Heading6 text="Name" />
                                    <div className="bordered-input">
                                        <Input placeholder={"Name your To-Do list..."} onChange={e => props.guidesAPI().updateCreateTodoListName(e.target.value)} />
                                    </div>
                                </div>

                                <div className="bordered-input-container">
                                    <Heading6 text="Account" />
                                    <div className="bordered-input">
                                        <Select options={accountSelectOptions} onChange={(valueType, actionType) => {
                                            if (actionType.action === 'select-option') {
                                                props.guidesAPI().updateCreateTodoListAccount(valueType);
                                            }
                                        }} />
                                    </div>
                                </div>

                                <div className="bordered-input-container">
                                    <Heading6 text="Description" />
                                    <div className="bordered-input">
                                        <textarea className="bordered-textarea" rows={7} placeholder={"ToDo description"} onChange={e => props.guidesAPI().updateCreateTodoListDescription(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <Button img={plusIcon} value="Add To-Do list" onClick={props.guidesAPI().createTodoListOnClick} />
                        </div>
                    </div>
                );

            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active="todos" onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            dataSource={props.todos ?? []}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'To Do',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'account',
                                    title: 'Account',
                                    render: (text, data) => <Text text={text} />,
                                },
                                // {
                                //     key: 'contact',
                                //     title: 'Contact',
                                //     render: (text, data) => <Text text={text} />,
                                // },
                                {
                                    key: 'createdDate',
                                    title: 'Created',
                                    render: (text, data) => <Text text={readableTime(data.createdDate.seconds, currentTimeInSeconds)} />,
                                },
                                {
                                    key: 'lastUpdated',
                                    title: 'Updated',
                                    render: (text, data) => <Text text={readableTime(data.lastUpdated.seconds, currentTimeInSeconds)} />,
                                },
                                // {
                                //     key: 'completed',
                                //     title: 'Completed',
                                //     render: (text, data) => {
                                //         const progress = Math.round((data.taskStatus.complete/data.taskStatus.outstanding) * 100);
                                //         return <div className="contact-details-circle-progress-container">
                                //             <Text className="contact-details-circle-element " text={`${data.taskStatus.complete}/${data.taskStatus.outstanding}`} />
                                //             <div className="contact-details-circle-element">
                                //                 <CircleProgress value={progress} className="contact-details-circular-progress" />
                                //                 <Heading6 text={`${progress}%`} />
                                //             </div>
                                //         </div>
                                //     },
                                // },
                            ]}
                        />
                    </div>
                    {CreateTodoFloatingPane}
                </div>
            );
        } else if (pageStatus && pageStatus === 'todoDetails' ) {

            const todo = props.todos[props.todoDetailsIndex];

            const newSectionButton = <Button img={plusGreenIcon} value="Add a new section" tone="light" className="todo-list-add-section-button" onClick={props.guidesAPI().updateAddSectionStatus}/>;
            const newSectionInputButton = (<>
                <Input className={'todo-list-add-section-input-button'} value={props.newSectionName} onChange={e => props.guidesAPI().updateNewSectionName(e.target.value)}/>
                <Button value="Save section" className="todo-list-save-section-button" onClick={props.guidesAPI().saveNewSection} />
            </>);

            const sectionButtonInput = (props.addingTodoSection ? newSectionInputButton : newSectionButton);

            const dueDatePicker = <DatePicker 
                onChange={newValue => props.guidesAPI().setTodoListDueDate(newValue)}
                inputPlaceholder="Add a due date..."
                inputClassName="todo-list-details-due-date-input"/>;

            const dueDateComponent = (todo.dueDate ?  <Heading5 text={dueDateFormattedTime(todo.dueDate)} /> : dueDatePicker);

            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="todo-details-header">
                        <div className="todo-details-header-title">
                            <Heading3 text={todo && todo.name} />
                            <span className="todo-details-header-separator"></span>
                            <Heading3 text={todo && todo.account} />
                            <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => props.guidesAPI().updatePageStatus("todos")}/>
                        </div>

                        <Text text={todo.description} className="todo-details-header-description" />

                        <div className="todo-details-header-title-details-container">
                            <div className="todo-details-header-title-details-box">
                                <div className="todo-details-header-title-details-box-label">
                                    <SVG src={userHexagonIcon} size={21} />
                                    <Text text="Owner" />
                                </div>

                                <Heading5 text={todo.owner} />
                            </div>

                            <div className="todo-details-header-title-details-box">
                                <div className="todo-details-header-title-details-box-label">
                                    <SVG src={calendarIcon} size={21} />
                                    <Text text="Due Date" />
                                </div>

                                {dueDateComponent}
                            </div>
                        </div>
                    </div>

                    <div className="todo-list-body">
                        {todo && todo.sections && todo.sections.map((section, sectionIndex) => {

                            const addingNewTaskText = <Text text="Add another task..." className="todo-list-add-task" onClick={() => props.guidesAPI().updateAddingTaskStatus(sectionIndex)}/>;
                            const addingNewTaskInputButton = (<>
                                <Input className={'todo-list-add-section-input-button'} value={props.newTaskName} onChange={e => props.guidesAPI().updateNewTaskName(e.target.value)}/>
                                <Button value="Save task" className="todo-list-save-section-button" onClick={props.guidesAPI().saveNewTask} />
                            </>);
                            const taskTextInput = (props.addingTodoTaskSectionIndex === sectionIndex ? addingNewTaskInputButton : addingNewTaskText);

                            return (
                            <div key={section.sectionName} className="todo-list-container">
                                <Heading3 text={section.sectionName} className="todo-list-header" />
                                {section.tasks && section.tasks.map((task, taskIndex ) => {

                                    const taskDueDatePicker = <DatePicker 
                                        onChange={newValue => props.guidesAPI().addTaskDueDate(sectionIndex, taskIndex, newValue)}
                                        inputPlaceholder="Add a due date..."
                                        inputClassName="todo-list-details-due-date-input"/>;

                                    const taskDueDatePickerClass = (props.todoListTaskSelected && 
                                        props.todoListTaskSelected.sectionIndex === sectionIndex &&
                                        props.todoListTaskSelected.taskIndex === taskIndex ? "" : "todo-list-show-hover");

                                    const contactSelectOptions = props.contacts.map(contact => {
                                        const label = contact.fullName + " (" + contact.email + ")";
                                        const value = contact.contactId;
                                        return {
                                            label: label,
                                            value: value,
                                            fullName: contact.fullName
                                        };
                                    });
                                    //Set on change value and update task //<Text text="Assign an owner" className="todo-list-placeholder" onClick={() => console.log(contactSelectOptions)}/>


                                    return (
                                        <div key={task.taskName} className="todo-list-row">
                                            <Checkbox checked={task.isCompleted} onClick={() => props.guidesAPI().checkTask(sectionIndex, taskIndex)}/>
                                            <Heading6 text={task.taskName} className="todo-list-title" />
                                            
                                            <div className="todo-list-account">
                                                {task && task.taskOwner && task.taskOwner.fullName
                                                    ? (
                                                        <Text text={task.taskOwner.fullName} />
                                                    ) : (
                                                        <div className="todo-list-show-hover">
                                                            <Select options={contactSelectOptions} 
                                                                onChange={(valueType, actionType) => {
                                                                    if (actionType.action === 'select-option') {
                                                                        props.guidesAPI().selectTaskOwner(sectionIndex, taskIndex, valueType);
                                                                    }
                                                                }}/>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className="todo-list-date">
                                                {task && task.taskDueDate
                                                    ? (
                                                        <Text text={dueDateFormattedTime(task.taskDueDate)}/>
                                                    ) : (
                                                        <div className={taskDueDatePickerClass} onClick={() => props.guidesAPI().setActiveTask(sectionIndex, taskIndex)}>
                                                            {taskDueDatePicker}
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className="todo-list-actions">
                                                <div className="todo-list-show-hover">
                                                    <SVG src={trashRedIcon} size={21} className="todo-list-action" onClick={() => props.guidesAPI().deleteTask(sectionIndex, taskIndex)}/>
                                                </div>
                                            </div>
                                        </div>
                                )})}
                                {taskTextInput}
                            </div>
                        )})}
                        {sectionButtonInput}
                    </div>
                </div>
            );
        } else if (guides && guides.length === 0) {
            return (
                <div className="guides-container">
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <div className="guides-title-container">
                            <Heading3 text="Guides" />
                            <Button img={Plus} value="Create Guide" onClick={() => { props.guidesAPI().createNewGuideOnClick(props.uid) }} />
                        </div>
                    </div>

                    <div className="guides-blank-state-body">
                        <div className="guides-blank-state-body-text">
                            <Heading4 text="Looks like there is no guide created yet!" className="guides-blank-state-body-textone" />
                            <Text text="Fortunately, it is easy to create one" className="guides-blank-state-body-texttwo" />
                            <Button className="guides-blank-state-button"
                                img={Plus} value="Create Guide"
                                onClick={() => { props.guidesAPI().createNewGuideOnClick(props.uid) }} />
                        </div>
                        <div className="guides-blank-state-body-image">
                            <SVG src={guideBlankStateSVG} />
                        </div>
                    </div>
                </div>
            );
        } else {

            const getDeleteGuideModal = () => {
                if (pageStatus && pageStatus === 'deleteGuide' ) {
                    return (
                        <div className="delete-modal-overlay">
                            <div className="delete-modal-content">
                                <Heading4 text="Are you sure you want to delete this file?" />
                                <Text text="This action cannot be undo" className="delete-modal-description" />
                                <Button img={trashIcon} value="Yes Delete" className="delete-modal-button" onClick={() => props.guidesAPI().deleteGuide(props.uid)} />
                                <Button value="I Changed My Mind" tone="light" />
                            </div>
                        </div>
                    );
                }
            }

            const deleteGuideModal = getDeleteGuideModal();

            return (
                <div>
                    <GuidesHeader email={props.email} displayName={props.fullName} />
                    <div className="guides-title-border">
                        <GuidesSectionHeader headings={homeHeadings} active="guides" onTabClick={tab => {props.guidesAPI().updatePageStatus(tab)}} />
                    </div>
                    <div className="guides-table-container">
                        <Table
                            rowOnClick={props.guidesAPI().navigateToGuide}
                            dataSource={props.guides}
                            columns={[
                                {
                                    key: 'name',
                                    title: 'Guide name',
                                    render: (text, data) => <Heading5 text={text} />,
                                },
                                {
                                    key: 'createdDate',
                                    title: 'Created',
                                    render: (text, data) => <Text text={readableTime(data.createdDate.seconds, currentTimeInSeconds)} className="table-body-text"/>,
                                },
                                {
                                    key: 'lastUpdated',
                                    title: 'Updated',
                                    render: (text, data) => <Text text={readableTime(data.lastUpdated.seconds, currentTimeInSeconds)} className="table-body-text"/>,
                                },
                                // {
                                //     key: 'actions',
                                //     title: 'Actions',
                                //     titleClassName: 'table-head-actions',
                                //     render: (text, data) => <div className="table-body-actions">
                                //         <SVG src={trashRedIcon} onClick={() => props.guidesAPI().deleteGuide(data.guideId)} />
                                //     </div>,
                                // },
                            ]}
                        />
                    </div>
                    <Button className="create-guide-button" img={Plus} value="Create Guide" onClick={() => { props.guidesAPI().createNewGuideOnClick(props.uid) }} />
                    {deleteGuideModal}
                </div>
            );
        }
    };

    const guidesGroup = getGuidesGroup(props);

    return guidesGroup;
}

export default GuidesGroup;