import "./EditHeader.css";
import { Text, Heading5, Button, SVG } from ".";
import Play from "./assets/play.svg";
import Share from "./assets/share.svg";
import Edit from "./assets/edit.svg";

export const EditHeader = (props) => {

    console.log(props);

    if (!props.updatePageStatus) props = {...props, updatePageStatus: () => console.log('updatePageStatus called')};
    if (!props.guideName) props = {...props, guideName: ''};
    if (!props.userData) props = {...props, userData: {displayName: '', email: ''}};

    const navigateToPresent = () => {
        const presentPath = "/" + props.guideId + "/present";
        window.location.pathname = presentPath;
    }

    const navigateToGuides = () => {
        const guidesPath = "/guides";
        window.location.pathname = guidesPath;
    }

    return (
        <div className="edit-header">
            <div className="edit-header-container">
                <div className="edit-header-flex-container">
                    <div className="edit-header-flex-container">
                        <Heading5 text="Guides" className="edit-header-nav-links-pointer" onClick={navigateToGuides}/>
                        <Heading5 text="/" className="edit-header-slash" />
                        <Heading5 text={props.guideName} className="edit-header-nav-links" />
                        <SVG src={Edit} onClick={() => {props.updatePageStatus('editGuideDetails')}} className="edit-header-edit-svg" />
                    </div>
                    <div className="edit-header-flex-container">
                        <SVG src={Play} onClick={navigateToPresent} className="edit-header-present-button"/>
                        <Button img={Share} value="Share" className="edit-header-share-button" onClick={() => {props.updatePageStatus('shareGuide')}}/>
                        <div className="edit-header-name-email-container">
                            <Text
                                text={props.userData.displayName}
                                className="edit-header-display-name"
                            />
                            <Text
                                text={props.userData.email}
                                className="edit-header-email"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
