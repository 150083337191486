import getFirestoreDb from "./getFirestoreDb";
import { collection, addDoc, serverTimestamp, updateDoc, doc, arrayUnion } from "firebase/firestore"; 

const updateGuide = (uid, guideId, guideData) => {
    return new Promise(resolve => {

        const db = getFirestoreDb();
        const guidePath = "users/" + uid + "/guides/" + guideId;
        const docRef = doc(db, guidePath);
        const updatedGuideData = {
            lastUpdated: serverTimestamp(),
            ...guideData
        };

        updateDoc(docRef, updatedGuideData).then(() => {
            resolve();
        });

    });
};

export default updateGuide;

// import firebase from "firebase/compat/app";
// import "firebase/compat/firestore";
// import firebaseConfig from "../firebaseConfig.js";

// if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
// } else {
//     firebase.app();
// }


// let db = firebase.firestore();

// function updateGuide(guideId, guideData) {

//     return new Promise((resolve, reject) => {
//         const updateGuideData = {
//             lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
//             ...guideData
//         };
//         db.collection('guides').doc(guideId).update(updateGuideData).then(() => {
//             resolve(guideId + " updated");
//         }).catch(err => {
//             reject(err);
//         });
//     });
// }

// export default updateGuide;