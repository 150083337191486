import React from "react";
import "./Logo.css";

import LogoImg from "./assets/logo.svg";
import LogoDark from "./assets/logoDark.svg";

export const Logo = ({ type }) => {
  if (type === "dark") {
    return <img src={LogoDark} alt="Logo" className="logo-img" />;
  } else {
    return <img src={LogoImg} alt="Logo" className="logo-img" />;
  }
};
