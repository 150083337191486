import getFirestoreDb from "./getFirestoreDb";
import getUserGuides from "./getUserGuides";
import getUserSessions from "./getUserSessions";
import getUserContacts from "./getUserContacts";
import getUserAccounts from "./getUserAccounts";
import getUserTodos from "./getUserTodos";
import { doc, getDoc, } from "firebase/firestore";

const getUserData = (uid) => {
    return new Promise(resolve => {

        const db = getFirestoreDb();
        const docRef = doc(db, "users", uid);

        getDoc(docRef).then(docSnap => {
            if (docSnap.exists()) {

                const userData = {
                    uid: uid,
                    ...docSnap.data()
                }

                getUserGuides(userData).then(guidesData => {

                    getUserSessions(userData).then(sessionsData => {

                        getUserContacts(userData).then(contactsData => {

                            getUserAccounts(userData).then(accountsData => {

                                getUserTodos(userData).then(todosData => {

                                    let updatedSessions = sessionsData.map(session => {
                                        const foundGuide = guidesData.find(guide => guide.guideId === session.guideId);
                                        const attendees = session.participants.map(participantId => {
                                            const foundContact = contactsData.find(contact => contact.contactId === participantId);
                                            return (foundContact.email);
                                        });
                                        return ({
                                            ...session,
                                            guideName: foundGuide.name,
                                            attendees: attendees
                                        });
                                    });

                                    resolve({
                                        ...userData,
                                        guides: guidesData,
                                        sessions: updatedSessions,
                                        contacts: contactsData,
                                        accounts: accountsData,
                                        todos: todosData,
                                    });

                                }); 

                            });

                        }); 

                    });

                });

            } else {
                resolve(null);
            }
        });

    });
};

export default getUserData;