import './PresentGroup.css';
import { Frame, SVG, Heading6 } from ".";
import { PresentHeader } from './PresentHeader';
import ShareDark from "./assets/share_dark.svg";
import Paper from './assets/paper.svg';
import iFrame from './assets/iframe.svg';
import ArrowLeft from './assets/arrow-left.svg';
import ArrowRight from './assets/arrow-right.svg';
import Copy from './assets/copy.svg';
import { CenterFrame } from './CenterFrame';
import PresentationActionButton from './PresentationActionButton';
import Button from './Button';

export const PresentGroup = (props) => {
    console.log('PresentGroup props');
    console.log(props);

    //slide box elements list

    if (!props.presentAPI) {
        props = {
            ...props,
            presentAPI: () => {
                return {
                    updateSlidePosition: () => console.log('updateSlidePosition called'),
                    updateSessionStatus: () => console.log('udpateSessionStatus called')
                };
            }
        }
    };

    let slideBoxElements = <div></div>;

    if (props.guideData && props.guideData.slides) {
        slideBoxElements = props.guideData.slides.map((slide, index) => {
            let SVGIcon;
            let pdf;
            if (slide.type === 'iFrame') {
                SVGIcon = iFrame;
            } else if (slide.type === 'link') {
                SVGIcon = ShareDark;
            } else if (slide.type === 'pdf') {
                SVGIcon = Paper;
                pdf = slide.url;
            }
            let img;
            if (slide.image && slide.image.url) {
                img = slide.image.url
            };
            let text;
            if (slide.showText) {
                text = slide.title;
            }
            return (
                <Frame 
                    key={index}
                    slideIndex={index}
                    className={"present-frame"} 
                    SVGIcon={SVGIcon} 
                    onClick={() => props.presentAPI().updateSlidePosition(index)} 
                    img={img}
                    pdf={pdf}
                    showText={slide.showText}
                    showImg={slide.showImg}
                    showURL={slide.showURL}
                    title={slide.title}
                    description={slide.description}
                    url={slide.url}
                    showLink={slide.showLink}
                    linkName={slide.linkName}
                    selected={index === props.slidePosition}/>
            );
        });
    }



    // let slideBoxElements = props.guideData.slides.map((slide, index) => {
    //     const frameClassNames = (index === props.slidePosition ? "active mt-2": "mt-2");
    //     if (slide.type === 'iFrame') {
    //         return (
    //             <Frame 
    //                 key={index} 
    //                 className={frameClassNames} 
    //                 SVGIcon={iFrame} 
    //                 onClick={() => props.editAPI().updateSlidePosition(index)} />
    //         );
    //     };
    // });

    // const createSlideCenterPane = (
    //     <div className="content p-1 relative">
    //         <h1>Select your new slide type</h1>
    //     </div>);

    //slideData

    let presentSlideCenterPane = <div></div>;

    if (props.guideData) {
        presentSlideCenterPane = (
            <div className="present-center-frame-container">
                <CenterFrame slideData={props.guideData.slides[props.slidePosition]} className="present-center-frame" />
                <div className="present-navigation-container">
                    <SVG src={ArrowLeft} onClick={() => props.presentAPI().updateSlidePosition(props.slidePosition - 1)} width="24" className="present-nav-left" />
                    <Heading6 text={"Slide " + (props.slidePosition + 1) + " of " + props.guideData.slides.length} />
                    <SVG src={ArrowRight} onClick={() => props.presentAPI().updateSlidePosition(props.slidePosition + 1)} width="24" className="present-nav-right" />
                </div>
            </div>
        );
    } else {
        props = {
            ...props,
            guideData: {
                name: 'Default name'
            },
            userData: {
                displayName: 'Default name',
                email: 'default@email.com'
            },
        };
        presentSlideCenterPane = (
            <div className="present-center-frame-container"></div>
        );
    }

    let presentDetailsPane;

    if (props.pageStatus === 'presentationLive') {
        presentDetailsPane = (
            //UPDATE JSX FOR STOPPING PRESENTATION
            <div className="present-details-container">
                <PresentationActionButton state="stop" onClick={() => { props.presentAPI().updateSessionStatus() }} />
                <div className="present-sharing-link-container">
                    {/* TODO: provide a sharing URL here */}
                    <p className="present-sharing-link">{"https://app.slide.guide/" + props.guideData.guideId}</p>
                </div>
                <Button img={Copy} value={"Copy link"} tone="light" onClick={() => {props.presentAPI().saveGuideLinkToClipboard()}}/>
            </div>
        );
    } else if (props.pageStatus === 'presentationReady') {
        presentDetailsPane = (
            //UPDATE JSX FOR STARTING PRESENTATION
            <div className="present-details-container">
                <PresentationActionButton state="start" onClick={() => { props.presentAPI().updateSessionStatus() }} />
                <div className="present-sharing-link-container">
                    {/* TODO: provide a sharing URL here */}
                    <p className="present-sharing-link">{"https://app.slide.guide/" + props.guideData.guideId}</p>
                </div>
                <Button img={Copy} value={"Copy link"} tone="light" onClick={() => {props.presentAPI().saveGuideLinkToClipboard()}}/>
            </div>
        );
    }
    // } else {
    //     presentDetailsPane = (
    //         <div className="present-details-container">
    //             Start presenation
    //         </div>
    //     );
    // }

    // presentSlideCenterPane = (
    //     <div className="content p-1 relative">
    //         <CenterFrame slideData={props.guideData.slides[props.slidePosition]} className="mt-2 max-w-full max-h-75 mb-3" />
    //         <div className="pag-box text-center flex-container items-center justify-center bd-t-primary p-1 absolute w-100">
    //             <SVG src={ArrowLeft} onClick={() => props.editAPI().updateSlidePosition(props.slidePosition - 1)} width="24" className="mr-1" />
    //             <Heading6 text={"Slide " + (props.slidePosition + 1) + " of " + props.guideData.slidesOrder.length} />
    //             <SVG src={ArrowRight} onClick={() => props.editAPI().updateSlidePosition(props.slidePosition + 1)} width="24" className="ml-1" />
    //         </div>
    //     </div>
    // );

    //let centerPane = (props.pageStatus === 'createSlide' ? createSlideCenterPane : editSlideCenterPane );

    

    return (
        <div>
            <PresentHeader guideId={props.guideData.guideId} guideName={props.guideData.name} userData={props.userData} title={props.guideData.name} isPresenting={props.isPresenting} playOnClick={props.presentAPI().updateSessionStatus} />
            <div className="present-body-container">
                <div className="present-slide-container">
                    {slideBoxElements}
                </div>
                {presentSlideCenterPane}
                {presentDetailsPane}
            </div>
        </div>
    );
};

export default PresentGroup;


/*
    let slideBoxElements = [
        <Frame key={1} className="active mt-2" SVGIcon={Image} />,
        <Frame key={2} className="mt-2" SVGIcon={Calendar} />,
        <Frame key={3} className="mt-2" SVGIcon={Questions} />,
        <Frame key={4} className="mt-2" SVGIcon={Paper} />,
        <Frame key={5} className="mt-2" SVGIcon={Video} />,
        <Frame key={6} className="mt-2" SVGIcon={Notes} />,
        <Frame key={7} className="mt-2" SVGIcon={Charts} />,
        <Frame key={8} className="mt-2" SVGIcon={iFrame} />
    ];

    let slideTypes = [
        <Button key="Paper" img={Paper} value="Import Slides" className="bg-white text-left bd-primary mt-2" />,
        <Button key="Image" img={Image} value="Import Images" className="bg-white text-left bd-primary mt-2" />,
        <Button key="Questions" img={Questions} value="Questions" className="bg-white text-left bd-primary mt-2" />,
        <Button key="Calendar" img={Calendar} value="Calendar" className="bg-white text-left bd-primary mt-2" />,
        <Button key="Notes" img={Notes} value="Notes" className="bg-white text-left bd-primary mt-2" />,
        <Button key="Video" img={Video} value="Video" className="bg-white text-left bd-primary mt-2" />,
        <Button key="Chart" img={Chart} value="Charts" className="bg-white text-left bd-primary mt-2" />,
        <Button key="iFrame" img={iFrame} value="iFrame Embed" className="bg-white text-left bd-primary mt-2" />,
    ];
*/

