import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, } from "firebase/firestore";

const getGuideSlides = (uid, guideData) => {
    return new Promise(resolve => {

        if (guideData.slidesOrder && guideData.slidesOrder.length > 0) {
            const db = getFirestoreDb();
            const slidesLength = guideData.slidesOrder.length;
            let slidesLoaded = 0;
            let slidesArray = [];

            guideData.slidesOrder.forEach(slideId => {

                const slideDocPath = "users/" + uid + "/guides/" + guideData.guideId + "/slides/" + slideId;
                const slideDocRef = doc(db, slideDocPath)

                getDoc(slideDocRef).then(docSnap => {

                    if(docSnap.exists()) {

                        slidesLoaded = slidesLoaded + 1;
                        slidesArray.push({
                            ...docSnap.data(),
                            slideId: slideId
                        });

                        if (slidesLoaded === slidesLength) {
                            resolve(slidesArray);
                        }

                    };
                });
            });
        } else {
            resolve([]);
        }
    });
};

export default getGuideSlides;