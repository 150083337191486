import getFirebaseApp from "./getFirebaseApp";
import { getFirestore } from "firebase/firestore";

const getFirestoreDb = () => {

    const app = getFirebaseApp();
    const db = getFirestore(app);
    return db;
}

export default getFirestoreDb;