import "./PresentHeader.css";
import { Text, Heading5, Button, SVG } from ".";
import Play from "./assets/play.svg";
import Share from "./assets/share.svg";
import Edit from "./assets/edit.svg";
import Back from "./assets/back.svg";

export const PresentHeader = (props) => {

    if (!props.playOnClick) {props = {...props, playOnClick: () => console.log('playOnClick called')}};
    if (!props.guideName) props = {...props, guideName: ''};
    if (!props.userData) props = {...props, userData: {displayName: '', email: ''}};
    if (!props.onBack) { props = { ...props, onBack: () => { console.log('on back click')} } };

    const navigateBackToEdit = () => {
        const editGuidePath = "/" + props.guideId + "/edit";
        window.location.pathname = editGuidePath;
    }

    return (
        <div className="present-header">
            <div className="present-header-container">
                <div className="present-header-flex-container">
                    <div className="present-header-flex-container">
                        <div className="present-header-presenting-back-container">
                            <button className="present-header-presenting-back-button" type="button" onClick={navigateBackToEdit}>
                                <SVG src={Back} className="margin-0"></SVG>
                                <span className="present-header-presenting-back-text">Back</span>
                            </button>
                        </div>
                    </div>
                    <div className="present-header-flex-container">
                        <h3 className="present-header-presenting-title">{props.title}</h3>
                    </div>
                    <div className="present-header-flex-container">
                        <div className="present-header-name-email-container">
                            <Text text={props.userData.displayName} className="present-header-display-name" />
                            <Text text={props.userData.email} className="present-header-email" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
