const devConfig = {
  apiKey: "AIzaSyBIHiFTkZGPIZD_pNfwpLA9sN1IzBcmYkc",
  authDomain: "slide-guide-dev.firebaseapp.com",
  projectId: "slide-guide-dev",
  storageBucket: "slide-guide-dev.appspot.com",
  messagingSenderId: "96518694896",
  appId: "1:96518694896:web:3ab6e763b00abd8a2ddf35",
  measurementId: "G-9V6NCJBD2X",
};

const prodConfig = {
  apiKey: "AIzaSyCU3FsBXDDNhYDxP6Y_zsQErs5ctdZt-XU",
  authDomain: "slide-guide-prod.firebaseapp.com",
  databaseURL: "https://slide-guide-prod-default-rtdb.firebaseio.com",
  projectId: "slide-guide-prod",
  storageBucket: "slide-guide-prod.appspot.com",
  messagingSenderId: "835865626539",
  appId: "1:835865626539:web:abd7a4456f1ed456a274ee",
  measurementId: "G-8Y082CHH4F"
};

//const firebaseConfig = devConfig;
const firebaseConfig = prodConfig;

export default firebaseConfig;
