import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, } from "firebase/firestore";

const getUserSessions = (userData) => {
    return new Promise (resolve => {
        if (userData.sessions && userData.sessions.length > 0) {

            const db = getFirestoreDb();
            const sessionsLength = userData.sessions.length;
            let sessionsLoaded = 0;
            let sessionsArray = [];

            userData.sessions.forEach(sessionId => {
                const sessionDocPath = "users/" + userData.uid + "/sessions/" + sessionId;
                const sessionDocRef = doc(db, sessionDocPath);
                getDoc(sessionDocRef).then(docSnap => {

                    if(docSnap.exists()) {

                        sessionsLoaded = sessionsLoaded + 1;
                        sessionsArray.push({
                            ...docSnap.data(),
                            sessionId: sessionId
                        });

                        if (sessionsLoaded === sessionsLength) {
                            resolve(sessionsArray);
                        };
                        
                    };
                });
            })
        } else {
            resolve([]);
        }
    });

    


    //retreive all of the sessions details

}

export default getUserSessions;