import React from "react";
import "./AvatarInitials.css";

const AvatarInitials = ({fullName}) => {

    //Pull first two initials of just the first initial
    
    return (
        <div>TD</div>
    );
}

export default AvatarInitials;