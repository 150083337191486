import getRealTimeDatabase from "./getRealTimeDatabase";
import { ref, get } from "firebase/database";

 const getCurrentSessionState = (sessionId) => {
    return new Promise(resolve => {
        const db = getRealTimeDatabase();
        const sessionRef = ref(db, "sessions/" + sessionId + '/sessionState');
        get(sessionRef).then(data => {
            resolve(data);
        });
    });
 }

 export default getCurrentSessionState;