import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, } from "firebase/firestore";

const getGuide = (uid, guideId) => {
    return new Promise(resolve => {
        const db = getFirestoreDb();
        const guideDocPath = "users/" + uid + "/guides/" + guideId;
        const guideDocRef = doc(db, guideDocPath);
        getDoc(guideDocRef).then(docSnap => {
            if(docSnap.exists()) {
                resolve({
                    ...docSnap.data(),
                    guideId: guideId
                });
            }
        });
    });
};

export default getGuide;