import React from "react";
import getGuideViewPositionListener from "../functions/getGuideViewPositionListener.js";
import getCurrentUser from "../functions/getCurrentUser.js";
import getUserData from "../functions/getUserData.js";
import createUser from "../functions/createUser.js";
import addUserToSession from "../functions/addUserToSession.js";
import ViewGroup from "../components/ViewGroup.js";
import objectHash from "object-hash";


class View extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            guideId: props.guideId,
            slidePosition: 0,
            guideData: null,
            slidesLoaded: false,
            positionListener: null,
            sessionStatus: null,
            userData: null,
            contactAdded: false,
        };
    }

    componentDidMount() {

        //check login state
        const setViewState = async () => {

            const user = await getCurrentUser();

            if (!user) {window.location = "./login?viewGuide=" + this.props.guideId};

            let userData = await getUserData(user.uid);
      
            if (userData === null) {
      
              userData = await createUser(user);
      
            }

            const positionListener = getGuideViewPositionListener(this.state.guideId, sessionData => {

                if (sessionData && sessionData.slideState && sessionData.sessionState) {

                    if (sessionData.sessionState.sessionState === 'started' && this.state.contactAdded === false) {

                        const userEmailHash = objectHash.sha1(userData.email);
                        addUserToSession(this.state.guideId, userEmailHash, userData).then(() => {
                            this.setState({
                                contactAdded: true
                            })
                        });

                    } else if (sessionData.sessionState.sessionState === 'ended' && this.state.contactAdded === true) {

                        this.setState({
                            contactAdded: false
                        });
                        
                    }

                    this.setState({
                        slidePosition: sessionData.slideState.slidePosition,
                        sessionStatus: sessionData.sessionState.sessionState,
                        guideData: sessionData.sessionState.guideData,
                    });
                };
            });

            this.setState({
                slidesLoaded: true,
                positionListener: positionListener,
                userData: userData
            });
        
        }

        setViewState();

    }

    componentWillUnmount() {

        if (typeof this.state.positionListener === 'function') {
            this.state.positionListener.off();
        } 
    }

    render() {

        const props = {
            guideId: this.state.guideId,
            slidePosition: this.state.slidePosition,
            slidesLoaded: this.state.slidesLoaded,
            positionListener: this.state.positionListener,
            sessionStatus: this.state.sessionStatus,
            guideData: this.state.guideData,
        };

        return <ViewGroup {...props} />
    }
};

export default View;
