
import { PDFDocument } from "pdf-lib";
import createSlide from "./createSlide";
import updateSlide from "./updateSlide";
import getFirebaseStorage from "./getFirebaseStorage";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const loadSplitSavePDFSlides = async (uid, guideId, PDFInputFile) => {

    let buffer = await PDFInputFile.arrayBuffer();

    let pdfDoc = await PDFDocument.load(buffer);

    let pagesCount = pdfDoc.getPageCount();

    let newSlidesState = [];

    for (let i = 0; i < pagesCount; i++) {
        
        let subDoc = await PDFDocument.create();

        let [copiedPage] = await subDoc.copyPages(pdfDoc, [i]);

        subDoc.addPage(copiedPage);

        let subDocBytes = await subDoc.save();

        let subDocBlob = new Blob([subDocBytes.buffer], {type:'application/pdf'});

        let newSlide = await createSlide(uid ,guideId, 'pdf');
        console.log(newSlide.slideId);

        const storage = getFirebaseStorage();
        const storageRefLocation = guideId + "/" + newSlide.slideId + ".pdf";
        const pdfStorageRef = ref(storage, storageRefLocation);

        await uploadBytes(pdfStorageRef, subDocBlob);

        let downloadURL = await getDownloadURL(pdfStorageRef);

        await updateSlide(uid, guideId, newSlide.slideId, {url: downloadURL});

        let newSlideData = {
            slideId: newSlide.slideId,
            type: 'pdf',
            url: downloadURL
        };

        newSlidesState.push(newSlideData);

    }

    console.log(newSlidesState);

    return newSlidesState;

}

export default loadSplitSavePDFSlides;