import { Input } from "./Input";
import { Button } from "./Button";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { Divider } from "./Divider";
import { SVG } from "./SVG";
import EmailSVG from "./assets/email.svg"
import invalidEmailSVG from './assets/invalidEmail.svg';
import invalidEmailPlusSVG from './assets/invalidEmailPlus.svg';
import "./LoginForm.css";
import { Text } from "./Text";

export const LoginForm = (props) => {

  console.log('LoginForm props');
  console.log(props);

  const getEmailInput = ({ email, invalidEmail, updateEmail }) => {
    if (invalidEmail) {
      console.log('Invalid email');
      return (
        //UPDATE THIS JSX
        <div className="login-form-email-invalid">
          <div className="login-form-email-invalid-input-div">
            <SVG src={invalidEmailSVG} className="login-form-email-invalid-invalidemail-svg" />
            <Input type="email" value={email} className="invalid-email-input" onChange={e => updateEmail(e.target.value)}/>
          </div>
          <div className="login-form-email-invalid-span-div">
            <SVG src={invalidEmailPlusSVG} className="login-form-email-invalid-invalidemailplus-svg" />
            <Text text="Invalid email" className="login-form-email-invalid-text" />
          </div>

        </div>
      );
    } else {
      return (
        <div className="login-form-email-input">
          <SVG src={EmailSVG} />
          <Input type="email" value={email} onChange={e => updateEmail(e.target.value)}/>
        </div>
      );
    }
  }

  const emailInput = getEmailInput(props);

  return (
    <div className="login-form-container">
      <GoogleLoginButton onClick={props.googleAuthOnClick} text="Continue with Google" />
      <Divider value={'or'} />
      {emailInput}
      <div className="login-form-button-container">
        <Button value="Login" onClick={props.emailLinkAuthOnClick}/>
      </div>
    </div>
  );
};
