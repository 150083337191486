import { CenterFrame } from "./CenterFrame";
import "./ViewGroup.css";
import { Heading4 } from "./Heading4";
import { Heading5 } from "./Heading5";
import {LoadingGroup} from "./LoadingGroup";


export const ViewGroup = (props) => {

    if (props.slidesLoaded !== true) {
        return (
            <LoadingGroup loadingMessage="Loading session..." />
        );
    } else if (props.sessionStatus !== 'started') {
        return (
            <div className="view-group-waiting-container">
                <Heading4 text="Thanks for joining!" className="view-group-waiting-text-one"/>
                <Heading5 text="We are just waiting for the session to start..." className="view-group-waiting-text-two" />
            </div>
        );
    } else {
        console.log('slides loaded');
        const slidePosition = props.slidePosition;
        return (
            <div className="view-group-center-frame-container">
                <CenterFrame slideData={props.guideData.slides[slidePosition]} />
            </div>
        );
    } 

}

export default ViewGroup;