import React from 'react';
import { Button } from '.';
import Folder from './assets/folder.svg';
import './ImportPdf.css';
import { SVG } from './SVG';
import Pdf from './assets/pdf.svg';
import Import from './assets/import.svg';

const ImportPdf = ({ fileInput, selectSlideFileOnClick, slidesInputFileOnChange }) => {

  return (
    <div className="import-pdf-container">
      <input type="file" name="slide input" id="slides_input_file" hidden accept=".pdf" onChange={slidesInputFileOnChange} />
        {!fileInput && (
          <>
            <p className="import-pdf-text">Drag and drop a file here</p>
            <p className="import-pdf-text">or</p>
            <Button className="import-pdf-button" tone="outline" img={Folder} value="Browse" onClick={selectSlideFileOnClick}></Button>
            <div className="flex-1"></div>
            <p className="import-pdf-footer">PDF<br/>smaller than 10MB</p>
          </>
        )}
        {fileInput && (
          <div className="import-pdf-file-container">
              <div className="import-pdf-file">
                <SVG src={Pdf} className="m-0" />
                <p className="m-0 import-pdf-file-name">{fileInput.name}</p>
              </div>
          </div>
        )}
    </div>
  );
};

export default ImportPdf;
