import "./LoginFooter.css";
import { Text } from "./Text.js";

export const LoginFooter = ({ text1, text2 }) => {
  return (
    <div className="login-footer-container">
      <Text
        text={text1}
        className="login-footer-text"
      />
      <a href="/">
        <Text
          text={text2}
          className="login-footer-link"
        />
      </a>
    </div>
  );
};
