import React from "react";
import "./Button.css";

export const Button = ({ img, value, onClick, tone, className }) => {

  const buttonClass = () => {
    if (tone === 'light') {
      return ("button button-light " + className);
    } else if (tone === 'outline') {
      return ("button button-outline " + className);
    } else {
      return ("button " + className);
    }
  };

  return (
    <div className={buttonClass()} onClick={onClick}>
      {img &&  (
        <img src={img} alt="" />
      )}
      <span>{value}</span>
    </div>
  );
};

export default Button;
