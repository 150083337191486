import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, setDoc, updateDoc, arrayUnion} from "firebase/firestore";
import objectHash from "object-hash";

const createAccount = (uid, accountName, accountWebsite) => {
    return new Promise(resolve => {
        //get account domain
        const url = new URL(accountWebsite);
        const domain = url.host;
        //hash account domain
        const domainHash = objectHash.sha1(domain);

        const db = getFirestoreDb();
        const accountDoc = doc(db, 'users/' + uid + '/accounts/' + domainHash);

        getDoc(accountDoc).then(docSnap => {
            if (!docSnap.exists()) {

                const newAccount = {
                    name: accountName,
                    domain: domain,
                    accountId: domainHash
                };

                setDoc(accountDoc, newAccount).then(() => {
                    
                    const userDoc = doc(db, 'users/'+ uid);
                    updateDoc(userDoc, {
                        accounts: arrayUnion(domainHash)
                    }).then(() => {
                        resolve(newAccount);
                    });

                });

            } else {
                resolve(docSnap.value());
            }
        })
    });

}

export default createAccount;