import React from "react";
import getGuide from "../functions/getGuide";
import getGuideSlides from "../functions/getGuideSlides";
import PresentGroup from "../components/PresentGroup";
import navigateSlides from "../functions/navigateSlides";
import updateLiveSlidePosition from "../functions/updateLiveSlidePosition";
import updateSessionState from "../functions/updateSessionState";
import LoadingGroup from "../components/LoadingGroup";
import getCurrentUser from "../functions/getCurrentUser";
import getUserData from "../functions/getUserData";
import createUser from "../functions/createUser";
import getGuideViewParticipantsListener from "../functions/getGuideViewParticipantsListener";
import getCurrentSessionState from "../functions/getCurrentSessionState";
import createNewSession from "../functions/createNewSession";
import createContact from "../functions/createContact";
import addSessionToContact from "../functions/addSessionToContact";


class Present extends React.Component {

    constructor(props) {

        //establish state
        super(props);

        this.state = {
            guideLoaded: false,
            guideData: null,
            userData: null,
            userLoaded: false,
            slidePosition: 0,
            positionListener: null,
            participantListener: null,
            sessionStatus: null,
            pageStatus: "presentationReady",
            sessionId: null
        };

        this.presentAPI = this.presentAPI.bind(this);
    }

    componentDidMount() {

        const setCurrentUser = async () => {

            const user = await getCurrentUser();
        
            if (!user) {window.location.pathname = "/login";};
        
            let userData = await getUserData(user.uid);
        
            if (userData === null) {
        
                userData = await createUser(user);
        
            }

            let guideData = await getGuide(userData.uid, this.props.guideId);

            let guideSlides = await getGuideSlides(userData.uid, guideData);

            let guideDataState = {
                ...guideData,
                slides: guideSlides
            };

            const currentSessionState = await getCurrentSessionState(this.props.guideId);
            const currentPageStatus = currentSessionState.val().sessionState === "started" ? 'presentationLive' : 'presentationReady';
            const currentSessionId = currentSessionState.val().sessionId ? currentSessionState.val().sessionId : null;

            const participantListener = getGuideViewParticipantsListener(this.props.guideId, data => {
                console.log(data.key);
                console.log(data.val());

                const participantId = data.key;
                const participantData = data.val();
                
                if (this.state.sessionId === null) {
                    createNewSession(userData.uid, this.props.guideId).then(newSessionId => {

                        this.setState({
                            sessionId: newSessionId
                        });

                        createContact(userData.uid, participantData.email, participantId, participantData.fullName).then(() => {
                            addSessionToContact(userData.uid, participantId, newSessionId);
                        });

                    });
                } else {

                    createContact(userData.uid, participantData.email, participantId, participantData.fullName).then(() => {
                        addSessionToContact(userData.uid, participantId, this.state.sessionId);
                    });

                }
            });
        
            this.setState({
                userLoaded: true,
                userData: userData,
                guideData: guideDataState,
                guideLoaded: true,
                participantListener: participantListener,
                sessionStatus: currentSessionState.val().sessionState,
                pageStatus: currentPageStatus,
                sessionId: currentSessionId
            });
    
        };
    
        setCurrentUser();



    }

    componentWillUnmount() {
        if (typeof this.state.positionListener === 'function') {
            this.state.positionListener.off();
        }
    }

    presentAPI() {
        
        const updateSlidePosition = (newPosition) => {
            const validPositionUpdate = navigateSlides(this.state.slidePosition, newPosition, this.state.guideData.slidesOrder.length);
            updateLiveSlidePosition(this.props.guideId, validPositionUpdate)
            this.setState({
                slidePosition: validPositionUpdate
            })
        }

        const updateSessionStatus = () => {

            console.log('updatedSessionState click');
            if (this.state.sessionStatus === 'started') {
                updateSessionState(this.props.guideId, 'ended', null);
                this.setState({
                    sessionStatus: 'ended',
                    pageStatus: 'presentationReady',
                    sessionId: null
                });
            } else {
                updateSessionState(this.props.guideId, 'started', this.state.guideData);
                this.setState({
                    sessionStatus: 'started',
                    pageStatus: 'presentationLive'
                });

            }
        };

        const updatePageStatus = (newStatus) => {
            this.setState({
                pageStatus: newStatus
            })
        }

        const saveGuideLinkToClipboard = () => {
            console.log("saving to clipboard");
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                const guideShareLink = "https://app.slide.guide/" + this.state.guideData.guideId;
                navigator.clipboard.writeText(guideShareLink).then(res => {
                    //Set state for shareing toast
                }).catch(err=>console.log(err));
            }
        }

        return {
            updateSlidePosition,
            updateSessionStatus,
            updatePageStatus,
            saveGuideLinkToClipboard 
        };

    }

    render() {
        
        const props = {
            guideData: this.state.guideData, 
            userData: this.state.userData,
            presentAPI: this.presentAPI,
            slidePosition: this.state.slidePosition,
            pageStatus: this.state.pageStatus
        }

        const loadingView = <LoadingGroup loadingMessage="Loading guide..." />
        const editGroup = <PresentGroup {...props}/>;

        if (this.state.guideLoaded === true) {
            return editGroup;
        } else {
            return loadingView;
        }

    }
};

export default Present;
