import getRealTimeDatabase from "./getRealTimeDatabase.js";
import {ref, onValue} from "firebase/database";


const getGuideViewPositionListener = (sessionId, sessionDataFunction) => {

  const db = getRealTimeDatabase();
  const sessionRef = ref(db, 'sessions/' + sessionId);

  onValue(sessionRef,(snapshot) => {
    const sessionData = snapshot.val();
    if (typeof sessionDataFunction === 'function') {
      sessionDataFunction(sessionData);
    }
  });

  return sessionRef;
  
};

export default getGuideViewPositionListener;
