import getRealTimeDatabase from "./getRealTimeDatabase";
import {ref, onChildAdded } from "firebase/database";

const getGuideViewParticipantsListener = (sessionId, newParticipantDataFunction) => {

    const db = getRealTimeDatabase();
    const participantsRef = ref(db, 'sessions/' + sessionId + '/sessionState/participants' );

    onChildAdded(participantsRef, data => {
        newParticipantDataFunction(data);
    });

    return participantsRef;

}

export default getGuideViewParticipantsListener;
