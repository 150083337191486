import getRealTimeDatabase from "./getRealTimeDatabase.js";
import { ref, set } from "firebase/database";

const addUserToSession = (sessionId, userEmailHash, userData) => {

    const db = getRealTimeDatabase();
    const sessionRef = ref(db, "sessions/" + sessionId + "/sessionState/participants/" + userEmailHash);
    const userDataState = {
        "userEmailHash": userEmailHash,
        "email": userData.email,
        "fullName": userData.fullName,
    };

    return set(sessionRef, userDataState);
}

export default addUserToSession;