import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, } from "firebase/firestore";

const getUserTodos = userData => {
    return new Promise(resolve => {
        if (userData.todos && userData.todos.length > 0) {

            const db = getFirestoreDb();
            const todosLength = userData.todos.length;
            let todosLoaded = 0;
            let todosArray = [];
            
            userData.todos.forEach(todoId => {    
                const todoDocPath = "users/" + userData.uid + "/todos/" + todoId;
                const todoDocRef = doc(db, todoDocPath);
                getDoc(todoDocRef).then(docSnap => {

                    if(docSnap.exists()) {

                        todosLoaded = todosLoaded + 1;
                        todosArray.push({
                            ...docSnap.data(),
                            todoId: todoId
                        });

                        if (todosLoaded === todosLength) {
                            resolve(todosArray);
                        };
                        
                    };
                });

            });
        } else {
            resolve([]);
        }
    });
}

export default getUserTodos;