import React from "react";
import "./HomeViewGroup.css";
import { BrowserRouter, Routes, Route, Link, Navigate} from "react-router-dom";
import HomeNavigation from "../components/HomeNavigation";
import GuidesListGroup from "./GuidesListGroup";
import GuidesMyGuidesTable from "../components/GuidesMyGuidesTable";

const HomeViewGroup = ({userData}) => {

    //Remove when deploying to all users
    const homeRedirect = (
        <Navigate to='/' replace/>
    );  

    const renderHomeView = () => {
        if (userData) {
            return (
                <BrowserRouter>
                    <Routes>
                        <Route path="/home" element={homeRedirect} />
                        <Route path="guides/:guideId" element={<p>Edit guide</p>} />
                        <Route path="view/:guideId" element={<p>View guide</p>} />
                        <Route path="todos/:todoId" element={<p>Edit todo</p>} />
                        <Route path="/" element={<HomeNavigation fullName={userData.fullName} />}>
                            <Route path="/" element={<Navigate to='/guides' replace />} />
                            <Route path="guides" element={<GuidesListGroup />}>
                                <Route path="myguides" element={<GuidesMyGuidesTable guides={userData.guides}/>} />
                                <Route path="shared" element={<p>Shared guides</p>} />
                                <Route path="templates" element={<p>Templates</p>} />
                                <Route path="archived" element={<p>Archived guides</p>} />
                                <Route path="" element={<Navigate to='myguides' replace />} />
                            </Route>
                            <Route path="sessions" element={<p>Sessions path</p>} />
                            <Route path="contacts" element={<p>Contacts path</p>} />
                            <Route path="accounts" element={<p>Accounts path</p>} />
                            <Route path="todos" element={<div><p>Todos path</p><Link to="todos/123456">Edit to-do</Link></div>} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            );
        } else {
            return (
                <p>Loading...</p>
            );
        }
    }

    const homeView = renderHomeView(); 

    return homeView;
};

export default HomeViewGroup;