import React from "react";
import getUser from "./functions/getUser";
import HomeViewGroup from "./groups/HomeViewGroup";

class Slideguide extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            userData: null
        };

    }

    componentWillMount() {
        
        const setUserData = async () => {
            const userData = await getUser();
            this.setState({
                userData
            })
        };

        setUserData();

    }
    
    render() {

        return <HomeViewGroup userData={this.state.userData} />;
    }

}

export default Slideguide;