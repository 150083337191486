

function navigateSlides(currentPosition, newPosition, slidesArrayLength) {
    //less than zero
    if (
        typeof currentPosition !== 'number' ||
        typeof newPosition !== 'number' ||
        typeof slidesArrayLength !== 'number'
        ) {
        return 0;
    } else if (newPosition < 0) {
        return currentPosition;
    } else if (newPosition >= slidesArrayLength) {
        return currentPosition;
    } else {
        return newPosition
    }

}

export default navigateSlides
