
const getUser = () => {
    return new Promise(resolve => {
        resolve({
            email: 'trey@treydavis.co',
            fullName: 'Trey Davis',
            accounts: [],
            contacts: [],
            guides: [
                {
                    name: "Cinemark QBR",
                    createdDate: "Wed Jul 06 2022 14:26:51 GMT-0600 (Mountain Daylight Time)",
                    lastUpdated: "Wed Jul 08 2022 18:28:54 GMT-0600 (Mountain Daylight Time)"
                },
                {
                    name: "Expedia QBR",
                    createdDate: "Wed Jul 06 2022 14:26:51 GMT-0600 (Mountain Daylight Time)",
                    lastUpdated: "Wed Jul 08 2022 18:28:54 GMT-0600 (Mountain Daylight Time)"
                }
            ],
            sessions: [],
            todos: []
        });
    });
};

export default getUser;