import React from 'react';
import './Toast.css';

export const Toast = ({ img, text = 'Success', tone = 'default', children, }) => {
    const getToneClass = () => {
        switch(tone) {
            case 'default':
                return 'toast-default';
            default:
                return 'toast-default';
        }
    }

    return (
        <div className={`toast-container ${getToneClass()}`}>
            {img && <img className="toast-img" src={img}></img>}
            {children && children}
            {!children && (
                <p className="toast-text">{text}</p>
            )}
        </div>
    )
}

export default Toast;