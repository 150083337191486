import { LoginGroup } from "../components/LoginGroup";
import React from "react";
import LoadingGroup from "../components/LoadingGroup";
import getCurrentUser from "../functions/getCurrentUser";
import getFirebaseApp from "../functions/getFirebaseApp";
import validator from "email-validator";
import {getAuth, GoogleAuthProvider, signInWithRedirect, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authChecked: false,
      loginEmailSent: false,
      email: '',
      invalidEmail: false
    }

    this.updateEmail = this.updateEmail.bind(this);
    this.emailLinkAuthOnClick = this.emailLinkAuthOnClick.bind(this);
  }

  componentDidMount() {

    const checkUser = async () => {
      const user = await getCurrentUser();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const viewGuide = urlParams.get('viewGuide');
      const apiKey = urlParams.get('apiKey');
      if (apiKey) {
        const app = getFirebaseApp();
        const auth = getAuth(app);
        if (isSignInWithEmailLink(auth, window.location.href)) {
          let email = window.localStorage.getItem('emailForSignIn');
          signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              window.localStorage.removeItem('emailForSignIn');
              console.log(result);
              if (viewGuide) {
                const viewGuidePath = "./" + viewGuide;
                window.location = viewGuidePath;
              } else {
                window.location = "./guides"
              }
            })
            .catch((error) => {
              console.log(error);
              this.setState({
                authChecked: true,
              });
            });
        }
      }

      if (user && viewGuide) {
        const viewGuidePath = "./" + viewGuide;
        window.location = viewGuidePath;
      } else if (user) {
        window.location = "./guides"
      } else if (!apiKey) {
        this.setState({
          authChecked: true,
        });
      }
    };

    checkUser();

  }

  updateEmail(newEmail) {
    this.setState({
      email: newEmail,
      invalidEmail: false
    });
  };

  googleAuthOnClick() {
    const app = getFirebaseApp();
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  }

  emailLinkAuthOnClick() {
    console.log('email link onclick');
    const validEmail = validator.validate(this.state.email);
    if (validEmail) {
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };
      const app = getFirebaseApp();
      const auth = getAuth(app);
      sendSignInLinkToEmail(auth, this.state.email, actionCodeSettings)
        .then(res => {
          console.log(res);
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', this.state.email);
          this.setState({
            loginEmailSent: true
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        invalidEmail: true
      });
    }
  }

  render() {

    const props = {
      googleAuthOnClick: this.googleAuthOnClick,
      emailLinkAuthOnClick: this.emailLinkAuthOnClick,
      updateEmail: this.updateEmail,
      loginEmailSent: this.state.loginEmailSent,
      email: this.state.email,
      invalidEmail: this.state.invalidEmail,
    }

    if (this.state.authChecked === false) {
      return (
        <LoadingGroup loadingMessage="Loading Slideguide..." />
      );
    }
    return (
      <LoginGroup {...props} />
    );
  }
};

export default Login;
