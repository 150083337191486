import getFirestoreDb from "./getFirestoreDb";
import { doc, updateDoc} from "firebase/firestore";

const updateTodo = (uid, todoId, data) => {
    return new Promise(resolve => {
        const db = getFirestoreDb();
        const todoDoc = doc(db, 'users/' + uid + '/todos/' + todoId);
        updateDoc(todoDoc, data).then(() => {
            resolve(true);
        });
    });
}

export default updateTodo;