import { Logo, Heading2, LoginFooter } from "../components";
import { LoginForm } from "./LoginForm";
import React from "react";
import "./LoginGroup.css";
import logoEmailSentPageSVG from "./assets/logoEmailSentPageSVG.svg";
import imageEmailSentPageSVG from "./assets/imageEmailSentPageSVG.svg";
import { SVG } from "./SVG";
import { Text } from "./Text";
import { Heading3 } from "./Heading3";


export const LoginGroup = (props) => {

    console.log('LoginGroup props');
    console.log(props);

    const getLoginGroup = ({ loginEmailSent }) => {
        if (loginEmailSent) {
            console.log('loginEmailSent');
            return (
                //ADD EMAIL SENT PAGE HERE
                <div className="login-group-email-sent">
                    <div className="login-group-email-sent-header">
                        <SVG src={logoEmailSentPageSVG} className="login-group-email-sent-header-logo-svg" />
                    </div>
                    <div className="login-group-email-sent-body">
                        <div className="login-group-email-sent-svg-image"> <SVG src={imageEmailSentPageSVG} /></div>
                        <div><Heading3 text="Email sent" className="login-group-email-sent-body-text-one" />
                            <Text text="We have sent an email confirmation to the email provided! Please have a look"
                                className="login-group-email-sent-body-text-two" /></div>

                    </div>
                    <div className="login-group-email-sent-footer">
                        <LoginFooter text1="Didn't get the email" text2="Resend Email" />
                    </div>

                </div>
            );
        } else {
            return (
                <div className="login-group">
                    <div className="login-group-left-pane">
                        <div className="login-group-logo-container">
                            <Logo />
                        </div>
                    </div>
                    <div className="login-group-right-pane">
                        <div className="login-group-right-container">
                            <Heading2 text="Welcome back," />
                            <LoginForm {...props} />
                            <LoginFooter text1="Don't have an account yet?" text2="Join the waitlist!" />
                        </div>
                    </div>
                </div>
            );
        }
    }

    const loginGroup = getLoginGroup(props);

    return loginGroup;
}