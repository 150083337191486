import React from 'react';
import checkIcon from './assets/check.svg';
import './Checkbox.css';

const Checkbox = ({ checked, onClick }) => {
  return (
    <div className={`checkbox ${checked ? 'checked' : ''}`} onClick={onClick}>
        {checked && (<img src={checkIcon} />)}
    </div>
  )
}

export default Checkbox;