import { useEffect, useState, useRef } from 'react';
import Button from './Button';
import Share from './assets/share.svg';
import './CenterFrame.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export const CenterFrame = ({ slideData }) => {
    const container = useRef(null);
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    });

    const handleResize = () => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        });
    }

    useEffect(() => {    
        window.addEventListener('resize', handleResize);
    
        return _ => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderSlideType = (slideData) => {
        if (!slideData || !slideData.type) {
            return <div></div>;
        } else if (slideData.type === 'iFrame' && slideData.url) {
            return (
                <div className={`centerFrame`}>
                    <iframe className="iFrame" src={slideData.url} />
                </div>
            );
        } else if (slideData.type === 'link' && slideData.url && slideData.linkName) {
            //GET the link name
            return (
                <div className={`centerFrame centerFrame-link-conatiner`}>
                    <Button img={Share} className="centerFrame-link-button" value={slideData.linkName} onClick={()=>{window.open(slideData.url, '_blank')}} />
                </div>
            );
        } else if (slideData.type === 'pdf' && slideData.url) {
            //UPDATE THIS COMPONENT TO FIT THE PDF IN THE CENTER FRAME
            return (
                <div className="centerFrame-pdf">
                    <div ref={container}>
                        <Document file={slideData.url} 
                            onLoadSuccess={()=>{console.log('PDF loaded'); handleResize()}}
                            loading={<div className='centerFrame-loading-div'></div>}>
                            <Page pageNumber={1} renderMode="svg" 
                                width={container && container.current ? container.current.offsetWidth : 0}
                                loading={<div className='centerFrame-loading-div'></div>} 
                                ></Page>
                        </Document>
                    </div>
                </div>
            ); 
        } else {
            return <div></div>;
        }
    }

    const slide = renderSlideType(slideData);

    return slide;

};