import getRealTimeDatabase from "./getRealTimeDatabase.js";
import { ref, set } from "firebase/database";

const updateSessionState = (sessionId, sessionState, guideData) => {

    const db = getRealTimeDatabase();
    const sessionRef = ref(db, "sessions/" + sessionId + "/sessionState");
    const sessionStateData = {
        "sessionState": sessionState,
        "guideData":  guideData,
        "participants": null
    };

    return set(sessionRef, sessionStateData);
}

export default updateSessionState;