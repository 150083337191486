import getFirebaseApp from "./getFirebaseApp";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const getCurrentUser = () => {
    return new Promise(resolve => {
        const app = getFirebaseApp();
        const auth = getAuth(app);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user);
            } else {
                resolve(null);
            }
        });
    });
}

export default getCurrentUser;
