import React from "react";
import Login from "../pages/Login"
import Guides from "../pages/Guides"
import Edit from "../pages/Edit"
import Present from "../pages/Present";
import View from "../pages/View"
import Slideguide from "../Slideguide/Slideguide";

const renderPages = function (pathDetails) {

    if (typeof pathDetails !== 'object') {

        return { error: "pathDetails is not an object" }

    } else if (pathDetails.hasOwnProperty("page") === false) {

        return { error: "pathDetails does not have a 'page' property" }

    } else if (pathDetails.page === 'login') {

        return <Login />

    } else if (pathDetails.page === 'guides') {

        return <Guides />

    } else if (pathDetails.page === 'edit') {

        return <Edit guideId={pathDetails.guideId} />

    } else if (pathDetails.page === 'present') {

        return <Present guideId={pathDetails.guideId} />

    } else if (pathDetails.page === 'view') {

        return <View guideId={pathDetails.guideId} />

    } else if (pathDetails.page === 'home') {

        return <Slideguide />

    } else {

        return { error: 'pathDetails.page value is not valid' }

    }

}

export default renderPages;