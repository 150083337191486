import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, } from "firebase/firestore";

const getUserGuides = (userData) => {
    return new Promise(resolve => {
        if (userData.guides && userData.guides.length > 0) {

            const db = getFirestoreDb();
            const guidesLength = userData.guides.length;
            let guidesLoaded = 0;
            let guidesArray = [];
            
            userData.guides.forEach(guideId => {    
                const guideDocPath = "users/" + userData.uid + "/guides/" + guideId;
                const guideDocRef = doc(db, guideDocPath);
                getDoc(guideDocRef).then(docSnap => {

                    if(docSnap.exists()) {

                        guidesLoaded = guidesLoaded + 1;
                        guidesArray.push({
                            ...docSnap.data(),
                            guideId: guideId
                        });

                        if (guidesLoaded === guidesLength) {
                            resolve(guidesArray);
                        };
                        
                    };
                });

            });
        } else {
            resolve([]);
        }
    });
}

export default getUserGuides;
