import getFirestoreDb from "./getFirestoreDb";
import { doc, updateDoc, arrayUnion, collection, serverTimestamp, addDoc } from "firebase/firestore";

const createTodoList = (userId, todoListName, todoListDescription, todoListAccountName) => {
    return new Promise(resolve => {
        const db = getFirestoreDb();
        const todoListCollection = collection(db, 'users/' + userId + '/todos');
        const todoListDoc = {
            contact: "",
            account: todoListAccountName,
            lastSession: "",
            name: todoListName,
            accountId: "",
            createdDate: serverTimestamp(),
            dueDate: null,
            lastUpdated: serverTimestamp(),
            description: todoListDescription,
            sections: [],
            taskStatus: {
                outstanding: 0,
                complete: 0,
            }
        };

        addDoc(todoListCollection, todoListDoc).then(todoRef => {
            const userDoc = doc(db, 'users/' + userId);
            updateDoc(userDoc, {
                todos: arrayUnion(todoRef.id)
            }).then(() => {
                resolve({
                    todoId: todoRef.id,
                    ...todoListDoc
                });
            });
        });
    });
};

export default createTodoList;