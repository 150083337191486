import React from "react";

import "./SVG.css";
import EmailSVG from './assets/email.svg';

export const SVG = ({ src, className, onClick }) => {

    if (!src) {src = EmailSVG};
    if (!onClick) {onClick = () => console.log('SVG onClick called')};

    return (
        <img
            src={src}
            alt="Icon"
            className={`svg ${className ? className : ''}`}
            onClick={onClick}
        />
    );
};