import getFirestoreDb from "./getFirestoreDb";
import { doc, updateDoc } from "firebase/firestore";

const updateSlide = (uid, guideId, slideId, slideData) => {
    return new Promise(resolve => {

        const db = getFirestoreDb();
        const slidePath = "users/" + uid + "/guides/" + guideId + "/slides/" + slideId;
        const docRef = doc(db, slidePath);

        updateDoc(docRef, slideData).then(() => {
            resolve(true);
        });

    });
}

export default updateSlide;
