import getRealTimeDatabase from "./getRealTimeDatabase.js";
import { ref, set } from "firebase/database";

const updateLiveSlidePosition = (sessionId, position) => {

    const db = getRealTimeDatabase();
    const sessionRef = ref(db, 'sessions/' + sessionId + '/slideState');
    const slideStateData = {
        slidePosition: position
    };
    return set(sessionRef, slideStateData);
};

export default updateLiveSlidePosition;