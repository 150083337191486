import getFirestoreDb from "./getFirestoreDb";
import { collection, addDoc, serverTimestamp, updateDoc, doc, arrayUnion } from "firebase/firestore"; 

const createNewGuide = uid => {
    return new Promise(resolve => {

        const db = getFirestoreDb();
        const guidePath = "users/" + uid + "/guides";
        const collectionRef = collection(db, guidePath);
        const newGuideDoc = {
            createdDate: serverTimestamp(),
            lastUpdated: serverTimestamp(),
            deleted: false,
            name: 'My new guide',
            owner: uid,
            slidesOrder: [],
            deletedSlides: [],
            sessions: []
        };

        addDoc(collectionRef,newGuideDoc).then(res => {

            const userRef = doc(db, 'users', uid);
            const docId = res.id;
            const userGuidesUpdate = {
                guides: arrayUnion(docId)
            };

            updateDoc(userRef,userGuidesUpdate).then(res => {
                resolve(docId);
            });

        });

    });

}

export default createNewGuide;