import getFirebaseApp from "./getFirebaseApp";
import { getStorage } from "firebase/storage";

const getFirebaseStorage = () => {

    const app = getFirebaseApp();
    const storage = getStorage(app);
    return storage;

}

export default getFirebaseStorage;