import React from "react";
import getCurrentUser from "../functions/getCurrentUser";
import getUserData from "../functions/getUserData";
import createUser from "../functions/createUser";
import getGuide from "../functions/getGuide";
import getGuideSlides from "../functions/getGuideSlides";
import EditGroup from "../components/EditGroup";
import navigateSlides from "../functions/navigateSlides";
import createSlide from "../functions/createSlide";
import { PDFDocument } from "pdf-lib";
import loadSplitSavePDFSlides from "../functions/loadSplitSavePDFSlides";
import LoadingGroup from "../components/LoadingGroup";

import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/functions";
import "firebase/storage";
import firebaseConfig from "../firebaseConfig.js";
import {arrayMoveImmutable} from 'array-move';
import updateGuide from "../functions/updateGuide";

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}

Window.PDFDocument = PDFDocument;

class Edit extends React.Component {

    constructor(props) {

        //establish state
        super(props);

        this.state = {
            userData: null,
            guideLoaded: false,
            guideData: null,
            userData: null,
            slidePosition: 0,
            pageStatus: "editSlides",
            fileInput: null,
            updatedGuideData: null,
            iFrameCreateSlideURL: "",
            iFrameCreateValidUrlWarning: false,
            linkCreateSlideURL: "",
            linkCreateSlideName: "",
            linkCreateValidUrlWarning: false,
            linkeCreateSlideNameWarning: false
        };

        this.editAPI = this.editAPI.bind(this);
    }

    componentDidMount() {

        const loadUserGuides = async () => {

            const user = await getCurrentUser();
      
            if (!user) {window.location.pathname = "/login";};
      
            let userData = await getUserData(user.uid);
      
            if (userData === null) {
      
              userData = await createUser(user);
      
            }

            const guideData = await getGuide(userData.uid, this.props.guideId);

            const guideSlides = await getGuideSlides(userData.uid, {guideId: this.props.guideId, ...guideData});

            const guideState = {
                ...guideData,
                guideId: this.props.guideId,
                slides: guideSlides,
            };

            this.setState({
              userLoaded: true,
              userData: userData,
              guideLoaded: true,
              guideData: guideState,
              updatedGuideData: {name: guideState.name}
            });
      
        };
      
        loadUserGuides();

    }

    editAPI() {
        
        const updateSlidePosition = (newPosition) => {
            console.log('updateSlidePosition: ' + newPosition);
            const validPositionUpdate = navigateSlides(this.state.slidePosition, newPosition, this.state.guideData.slides.length);
            console.log(validPositionUpdate);
            this.setState({
                slidePosition: validPositionUpdate,
                pageStatus: "editSlides",
                fileInput: null
            });
        }

        const updatePageStatus = (newPageStatus) => {
            this.setState({
                pageStatus: newPageStatus
            });
        };

        const iFrameCreateSlideOnClick = () => {

            //validate URL
            try {
                new URL(this.state.iFrameCreateSlideURL);
            } catch {
                console.log('Invalid url');
                this.setState({
                    iFrameCreateValidUrlWarning: true
                });
                return;
            }

            const slideData = {
                url: this.state.iFrameCreateSlideURL,
                showURL: true,
            };

            createSlide(this.state.userData.uid, this.props.guideId, "iFrame", slideData).then(newSlideRes => {
                const newSlideId = newSlideRes.slideId;
                const newSlide = {...newSlideRes.slideData, slideId: newSlideId};
                let updatedGuideData = this.state.guideData;
                updatedGuideData.slides.push(newSlide);
                updatedGuideData.slidesOrder.push(newSlideId);
                const newSlidePosition = updatedGuideData.slidesOrder.length - 1;
                this.setState({
                    guideData: updatedGuideData,
                    pageStatus: 'editSlides',
                    slidePosition: newSlidePosition,
                    iFrameCreateSlideURL: ""

                });
            }).catch(err => console.log(err));
        }

        const linkCreateSlideOnClick = () => {

            if (this.state.linkCreateSlideName.length === 0) {
                this.setState({
                    linkeCreateSlideNameWarning: true
                });
            }

            try {
                new URL(this.state.linkCreateSlideURL);
            } catch {
                console.log('Invalid url');
                this.setState({
                    linkCreateValidUrlWarning: true
                });
                return;
            }

            if (this.state.linkCreateSlideName.length === 0) {
                return;
            }

            const slideData = {
                url: this.state.linkCreateSlideURL,
                linkName: this.state.linkCreateSlideName,
                showLink: true,
            };

            createSlide(this.state.userData.uid ,this.props.guideId, "link", slideData).then(newSlideRes => {
                const newSlideId = newSlideRes.slideId;
                const newSlide = {...newSlideRes.slideData, slideId: newSlideId};
                let updatedGuideData = this.state.guideData;
                updatedGuideData.slides.push(newSlide);
                updatedGuideData.slidesOrder.push(newSlideId);
                const newSlidePosition = updatedGuideData.slidesOrder.length - 1;
                this.setState({
                    guideData: updatedGuideData,
                    pageStatus: 'editSlides',
                    slidePosition: newSlidePosition,
                    iFrameCreateSlideURL: ""

                });
            }).catch(err => console.log(err));
        }

        const selectSlideFileOnClick = () => {
            console.log('selectSlidesFileOnClick');
            let file_input = document.getElementById('slides_input_file');
            console.log(file_input);
            file_input.click();
        }

        const slidesInputFileOnChange = (event) => {
            console.log('slidesInputFileOnChange event');
            console.log(event);
            Window.fileInput = event.target.files[0];
            this.setState({
                fileInput: event.target.files[0]
            });
        }

        const processPDFSlidesInput = async () => {

            this.setState({
                pageStatus: "importingPDFslides"
            });

            let newSlides = await loadSplitSavePDFSlides(this.state.userData.uid, this.props.guideId, this.state.fileInput);

            let guideData = this.state.guideData;

            let updatedSlides = [...guideData.slides, ...newSlides];

            guideData.slides = updatedSlides;
            guideData.slidesOrder = guideData.slides.map(slide => slide.slideId);

            this.setState({
                guideData: guideData,
                pageStatus: "editSlides",
                fileInput: null
            });
        }

        const updateSlidesOrder = (result) => {
            console.log(this.state);

            if (!result.source || !result.destination) return;
            console.log('drag and drop: move ' + result.source.index + ' to ' + result.destination.index);

            let sourceIndex = result.source.index;
            let destinationIndex = result.destination.index;

            let updatedSlidesList = arrayMoveImmutable(this.state.guideData.slides, sourceIndex, destinationIndex);
            let updatedGuideData = this.state.guideData;
            updatedGuideData.slides = updatedSlidesList;

            const newSlidesOrderList = updatedSlidesList.map(slide => slide.slideId);

            console.log(sourceIndex, destinationIndex);

            this.setState({
                guideData: updatedGuideData,
                slidePosition: destinationIndex
            });

            //let updatedSlidesOrder = arrayMoveImmutable(this.state.guideData.slidesOrder, sourceIndex, destinationIndex);

            console.log(newSlidesOrderList);

            updateGuide(this.state.userData.uid, this.props.guideId, {slidesOrder: newSlidesOrderList}).then(() => {
                console.log('slidesOrder updated');
            }).catch(err => console.log(err));

        }

        const deleteSlideOnClick = () => {
            console.log('deleteSlide onClick');

            //create copy of guide data
            //Update slidesOrder on guide data
            //Update deleted slides on guide data
            //Update slidse on guide data
            //Set state for guide data

            let updatedGuideData = this.state.guideData;
            let updatedSlidePosition = this.state.slidePosition;
            let guideId = this.props.guideId;
            let slideId = this.state.guideData.slides[this.state.slidePosition].slideId;

            updatedGuideData.slides.splice(this.state.slidePosition, 1);
            updatedGuideData.deletedSlides.push(slideId);
            updatedGuideData.slidesOrder = updatedGuideData.slides.map(slide => slide.slideId);

            if (updatedSlidePosition >= updatedGuideData.slides.length && updatedGuideData.slides.length > 0) {
                updatedSlidePosition = updatedGuideData.slides.length - 1;
            }

            console.log(updatedGuideData, updatedSlidePosition);
            this.setState({
                guideData: updatedGuideData,
                slidePosition: updatedSlidePosition,
                pageStatus: "editSlides"
            });

            updateGuide(this.state.userData.uid, guideId, {slidesOrder: updatedGuideData.slidesOrder, deletedSlides: updatedGuideData.deletedSlides});
            
        }

        const iFrameCreateSlideUrlUpdate = event => {
            console.log("Update iFrame on change called");
            const iFrameCreateSlideURL = event.target.value;
            this.setState({
                iFrameCreateSlideURL,
                iFrameCreateValidUrlWarning: false
            });
        }

        const linkCreateSlideUrlUpdate = event => {
            console.log("Update link on change called");
            const linkCreateSlideURL = event.target.value;
            this.setState({
                linkCreateSlideURL,
                linkCreateValidUrlWarning: false
            });
        }

        const linkCreateSlideNameUpdate = event => {
            console.log("Update link on change called");
            const linkCreateSlideName = event.target.value;
            this.setState({
                linkCreateSlideName,
                linkeCreateSlideNameWarning: false
            });
        }

        //guideNameOnChange

        const guideNameOnChange = event => {

            let updatedGuideData = this.state.updatedGuideData;
            updatedGuideData.name = event.target.value;
            this.setState({
                updatedGuideData
            });
        }

        //saveGuideData

        const saveGuideDataOnClick = () => {
            console.log('save guide clicked');
            let guideData = this.state.guideData;
            guideData.name = this.state.updatedGuideData.name;
            this.setState({
                guideData
            });

            updateGuide(this.state.userData.uid, this.props.guideId, {name: this.state.updatedGuideData.name});
        }

        const saveGuideLinkToClipboard = () => {
            console.log("saving to clipboard");
            if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                const guideShareLink = "https://app.slide.guide/" + this.props.guideId;
                navigator.clipboard.writeText(guideShareLink).then(res => {
                    this.setState({
                        pageStatus: 'shareGuideCopied'
                    });
                }).catch(err=>console.log(err));
            }
        }

        return {
            updateSlidePosition,
            updatePageStatus,
            selectSlideFileOnClick,
            slidesInputFileOnChange,
            processPDFSlidesInput,
            updateSlidesOrder,
            deleteSlideOnClick,
            guideNameOnChange,
            saveGuideDataOnClick,
            iFrameCreateSlideUrlUpdate,
            iFrameCreateSlideOnClick,
            saveGuideLinkToClipboard,
            linkCreateSlideUrlUpdate,
            linkCreateSlideNameUpdate,
            linkCreateSlideOnClick
        };

    }

    render() {
        
        const props = {
            guideData: this.state.guideData, 
            userData: this.state.userData,
            editAPI: this.editAPI,
            slidePosition: this.state.slidePosition,
            pageStatus: this.state.pageStatus,
            fileInput: this.state.fileInput,
            updatedGuideData: this.state.updatedGuideData,
            iFrameCreateSlideURL: this.state.iFrameCreateSlideURL,
            iFrameCreateValidUrlWarning: this.state.iFrameCreateValidUrlWarning,
            linkCreateSlideURL: this.state.linkCreateSlideURL,
            linkCreateSlideName: this.state.linkCreateSlideName,
            linkCreateValidUrlWarning: this.state.linkCreateValidUrlWarning,
            linkeCreateSlideNameWarning: this.state.linkeCreateSlideNameWarning
        }

        const loadingView = <LoadingGroup loadingMessage="Loading your guide..."/>
        const editGroup = <EditGroup {...props}/>;

        if (this.state.guideLoaded === true) {
            return editGroup;
        } else {
            return loadingView;
        }

    }
};

export default Edit;