import React from "react";
import GuidesGroup from "../components/GuidesGroup";
import getCurrentUser from "../functions/getCurrentUser";
import getUserData from "../functions/getUserData";
import createUser from "../functions/createUser";
import createNewGuide from "../functions/createNewGuide";
import LoadingGroup from "../components/LoadingGroup";
import createAccount from "../functions/createAccount";
import createContact from "../functions/createContact";
import createTodoList from "../functions/createTodoList";
import updateTodo from "../functions/updateTodo";
import objectHash from "object-hash";


class Guides extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      userLoaded: false,
      userData: null,
      uid: null,
      pageStatus: 'guides',
      createAccountName: '',
      createAccountWebsite: '',
      createContactName: '',
      createContactEmail: '',
      createContactAccount: '',
      createTodoListName: '',
      createTodoListDescription: '',
      createTodoListAccount: '',
      todoDetailsIndex: null,
      addingTodoSection: false,
      newSectionName: '',
      newTodoTaskName: '',
      addingTodoTask: false,
      addingTodoTaskSectionIndex: null,
      todoListTaskSelected: null,
    };

    this.guidesAPI = this.guidesAPI.bind(this);

  }

  componentDidMount() {

    const setCurrentUser = async () => {

      const user = await getCurrentUser();

      if (!user) {window.location.pathname = "/login"}; 

      let userData = await getUserData(user.uid);

      if (userData === null) {

        userData = await createUser(user);

      }

      if (userData && userData.featureFlags && userData.featureFlags["20220628_homeView"]) {
        console.log('feature flag enabled');
        window.location.pathname = "/home";
        return;
      }

      this.setState({
        userLoaded: true,
        userData: userData,
        uid: user.uid
      });

    };

    setCurrentUser();

  }

  guidesAPI() {

    const createNewGuideOnClick = uid => {
      createNewGuide(uid).then(guideId => {
          const newGuidePath = '/' + guideId + "/edit";
          window.location.pathname = newGuidePath;
      }).catch(err => console.log(err));
    }

    const updatePageStatus = newPageStatus => {
      this.setState({
        pageStatus: newPageStatus
      });
    }

    const updateCreateAccountName = newCreateAccountName => {
      this.setState({
        createAccountName: newCreateAccountName
      });
    }

    const updateCreateAccountWebsite = newCreateAccountWebsite => {
      this.setState({
        createAccountWebsite: newCreateAccountWebsite
      });
    }

    const createAccountOnClick = () => {

      createAccount(this.state.uid, this.state.createAccountName, this.state.createAccountWebsite).then(newAccount => {
        let userData = this.state.userData;
        userData.accounts.push(newAccount);
        this.setState({
          userData: userData,
          pageStatus: "accounts"
        })
      });
    }

    const updateCreateContactName = newCreateContactName => {
      this.setState({
        createContactName: newCreateContactName
      });
    }

    const updateCreateContactEmail = newCreateContactEmail => {
      this.setState({
        createContactEmail: newCreateContactEmail
      });
    }

    const createContactOnClick = () => {
      const emailHash = objectHash.sha1(this.state.createContactEmail);
      createContact(this.state.uid, this.state.createContactEmail, emailHash, this.state.createContactName, this.state.createContactAccount).then(() => {
        let userData = this.state.userData;
        const newContact = {
          contactId: emailHash,
          email: this.state.createContactEmail,
          fullName: this.state.createContactName,
          account: this.state.createContactAccount,
        };
        userData.contacts.push(newContact);
        this.setState({
          userData: userData,
          pageStatus: 'contacts'
        })
      })
    }

    const updateCreateTodoListName = newCreateTodoListName => {
      this.setState({
        createTodoListName: newCreateTodoListName
      });
    }

    const updateCreateTodoListDescription = newCreateTodoListDescription => {
      this.setState({
        createTodoListDescription: newCreateTodoListDescription
      });
    }

    const createTodoListOnClick = () => {
      createTodoList(this.state.uid, this.state.createTodoListName, this.state.createTodoListDescription, this.state.createTodoListAccount).then(newTodoList => {
        let userData = this.state.userData;
        userData.todos.push(newTodoList);
        const todoDetailsIndex = userData.todos.length - 1;
        this.setState({
          userData: userData,
          todoDetailsIndex: todoDetailsIndex,
          pageStatus: 'todoDetails',
          createTodoListName: '',
          createTodoListDescription: '',
          createTodoListAccount: '',
        });
      });
    }

    const navigateToGuide = (rowData) => {
      if (rowData.guideId) {
        const guidePath = '/' + rowData.guideId + '/edit';
        window.location.pathname = guidePath;
      }
    };

    const navigateToTodo = (rowData, rowIndex) => {
      console.log(rowData, rowIndex);
      if (rowData && rowIndex !== null) {
        this.setState({
          pageStatus: 'todoDetails',
          todoDetailsIndex: rowIndex
        });
      }
    };

    const updateAddSectionStatus = () => {
      this.setState({
        addingTodoSection: true
      })
    };

    const updateNewSectionName = newSectionName => {
      this.setState({
        newSectionName: newSectionName
      })
    };

    const saveNewSection = () => {

      let userData = this.state.userData;
      userData.todos[this.state.todoDetailsIndex].sections.push({
        sectionName: this.state.newSectionName,
        tasks: []
      });

      this.setState({
        userData: userData,
        newSectionName: '',
        addingTodoSection: false
      })

      const updateTodoId = userData.todos[this.state.todoDetailsIndex].todoId;
      const updateTodoData = {
        sections: userData.todos[this.state.todoDetailsIndex].sections
      }

      updateTodo(this.state.uid, updateTodoId, updateTodoData);
    };

    const updateAddingTaskStatus = (sectionIndex) => {
      this.setState({
        addingTodoTask: true,
        addingTodoTaskSectionIndex: sectionIndex
      });
    }

    const updateNewTaskName = (newTaskName) => {
      this.setState({
        newTodoTaskName: newTaskName
      });
    }

    const saveNewTask = () => {

      let userData = this.state.userData;
      userData.todos[this.state.todoDetailsIndex].sections[this.state.addingTodoTaskSectionIndex].tasks.push({
        taskName: this.state.newTodoTaskName
      });
      this.setState({
        userData: userData,
        newTodoTaskName: '',
        addingTodoTask: false,
        addingTodoTaskSectionIndex: null
      });

      const updateTodoId = userData.todos[this.state.todoDetailsIndex].todoId;
      const updateTodoData = {
        sections: userData.todos[this.state.todoDetailsIndex].sections
      }

      updateTodo(this.state.uid, updateTodoId, updateTodoData);
    }

    const deleteTask = (sectionIndex, taskIndex) => {

      let userData = this.state.userData;
      userData.todos[this.state.todoDetailsIndex].sections[sectionIndex].tasks.splice(taskIndex, 1);
      this.setState({
        userData: userData
      });

      const updateTodoId = userData.todos[this.state.todoDetailsIndex].todoId;
      const updateTodoData = {
        sections: userData.todos[this.state.todoDetailsIndex].sections
      }
      updateTodo(this.state.uid, updateTodoId, updateTodoData);
    }

    const checkTask = (sectionIndex, taskIndex) => {

      let userData = this.state.userData;
      userData.todos[this.state.todoDetailsIndex].sections[sectionIndex].tasks[taskIndex].isCompleted = true;
      this.setState({
        userData: userData
      });

      const updateTodoId = userData.todos[this.state.todoDetailsIndex].todoId;
      const updateTodoData = {
        sections: userData.todos[this.state.todoDetailsIndex].sections
      }
      updateTodo(this.state.uid, updateTodoId, updateTodoData);
    }

    const setTodoListDueDate = dueDate => {
      let userData = this.state.userData;
      userData.todos[this.state.todoDetailsIndex].dueDate = dueDate;
      this.setState({
        userData: userData
      });
      
      const updateTodoId = userData.todos[this.state.todoDetailsIndex].todoId;
      const updateTodoData = {
        dueDate: userData.todos[this.state.todoDetailsIndex].dueDate
      }
      updateTodo(this.state.uid, updateTodoId, updateTodoData);
    }

    const setActiveTask = (sectionIndex, taskIndex) => {
      this.setState({
        todoListTaskSelected:{
          sectionIndex: sectionIndex,
          taskIndex: taskIndex
        }
      });
    }

    const addTaskDueDate = (sectionIndex, taskIndex, dueDate) => {
      let userData = this.state.userData;
      userData.todos[this.state.todoDetailsIndex].sections[sectionIndex].tasks[taskIndex].taskDueDate = dueDate;
      this.setState({
        userData: userData,
        todoListTaskSelected: null
      });

      const updateTodoId = userData.todos[this.state.todoDetailsIndex].todoId;
      const updateTodoData = {
        sections: userData.todos[this.state.todoDetailsIndex].sections
      }
      updateTodo(this.state.uid, updateTodoId, updateTodoData);
    }

    const selectTaskOwner = (sectionIndex, taskIndex, taskOwner) => {
      let userData = this.state.userData;
      userData.todos[this.state.todoDetailsIndex].sections[sectionIndex].tasks[taskIndex].taskOwner = taskOwner;
      this.setState({
        userData: userData
      });

      const updateTodoId = userData.todos[this.state.todoDetailsIndex].todoId;
      const updateTodoData = {
        sections: userData.todos[this.state.todoDetailsIndex].sections
      }
      updateTodo(this.state.uid, updateTodoId, updateTodoData);
    };

    const updateNewContactAccount = (accountData) => {
      this.setState({
        createContactAccount: accountData.name
      })
    }

    const updateCreateTodoListAccount = (accountdata) => {
      this.setState({
        createTodoListAccount: accountdata.name
      })
    }

    return {
      createNewGuideOnClick,
      updatePageStatus,
      updateCreateAccountName,
      updateCreateAccountWebsite,
      createAccountOnClick,
      updateCreateContactName,
      updateCreateContactEmail,
      createContactOnClick,
      updateCreateTodoListName,
      updateCreateTodoListDescription,
      createTodoListOnClick,
      navigateToGuide,
      navigateToTodo,
      updateAddSectionStatus,
      updateNewSectionName,
      saveNewSection,
      updateAddingTaskStatus,
      updateNewTaskName,
      saveNewTask,
      deleteTask,
      checkTask,
      setTodoListDueDate,
      setActiveTask,
      addTaskDueDate,
      selectTaskOwner,
      updateNewContactAccount,
      updateCreateTodoListAccount
    };
  }

  render() {

    if (this.state.userLoaded === false) {
      return (
        <LoadingGroup loadingMessage="Loading your guides..."/>
      );
    } else {

      const props = {
        ...this.state.userData,
        uid: this.state.uid,
        pageStatus: this.state.pageStatus,
        createAccountName: this.state.createAccountName,
        createAccountWebsite: this.state.createAccountWebsite,
        createContactName: this.state.createContactName,
        createContactEmail: this.state.createContactEmail,
        createTodoListName: this.state.createTodoListName,
        createTodoListDescription: this.state.createTodoListDescription,
        todoDetailsIndex: this.state.todoDetailsIndex,
        addingTodoSection: this.state.addingTodoSection,
        newSectionName: this.state.newSectionName,
        newTodoTaskName: this.state.newTodoTaskName,
        addingTodoTask: this.state.addingTodoTask,
        addingTodoTaskSectionIndex: this.state.addingTodoTaskSectionIndex,
        todoListTaskSelected: this.state.todoListTaskSelected,
        guidesAPI: this.guidesAPI,
      };

      return (
        <GuidesGroup {...props} />
      );
    }

  }
};

export default Guides;