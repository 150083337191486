import React from "react";
import "./GuidesListGroup.css";
import GuidesNavigation from "../components/GuidesNavigation";
import ButtonCreateGuide from "../components/ButtonCreateGuide";
import { Outlet } from "react-router-dom";

const GuidesListGroup = () => {
    return (
        <div>
            <div>
                <GuidesNavigation />
                <ButtonCreateGuide />
            </div>
            <Outlet />
        </div>
    );
};

export default GuidesListGroup;