import getFirestoreDb from "./getFirestoreDb";
import { collection, addDoc, updateDoc, doc, arrayUnion } from "firebase/firestore"; 

const createSlide = (uid, guideId, slideType, slideData) => {
    return new Promise(resolve => {

        const newSlideData = (slideType, slideData) => {

            if (slideType === 'iFrame') {
                return {
                    type: 'iFrame',
                    ...slideData
                };
            } else if (slideType === 'link') {
                return {
                    type: 'link',
                    ...slideData
                };
            } else if (slideType === 'pdf') {
                return {
                    type: 'pdf',
                    url: null
                };
            }
        }

        const newSlide = newSlideData(slideType, slideData);

        const db = getFirestoreDb();
        const slidePath = "users/" + uid + "/guides/" + guideId + "/slides";
        const slideRef = collection(db, slidePath);

        addDoc(slideRef, newSlide).then(res => {

            const guidePath = "users/" + uid + "/guides/" + guideId;
            const guideRef = doc(db, guidePath);
            const guideSlidesOrderUpdate = {
                slidesOrder: arrayUnion(res.id)
            }

            updateDoc(guideRef, guideSlidesOrderUpdate).then(() => {
                resolve({
                    slideId: res.id,
                    slideData: newSlide
                });
            })

        });

    });
}

export default createSlide;