function pathNameParse(path) {

    if (typeof path !== 'string') {

        return { error: 'Path argument is not a string' }

    } else if (path[0] !== '/') {

        return { error: 'Invalid path does not start with "/"' }

    }

    let pathSplit = path.split("/");
    pathSplit.splice(0, 1);

    if (pathSplit.length === 1) {

        if (pathSplit[0] === 'login') {

            return { page: "login" };

        } else if (pathSplit[0] === 'signup') {

            return { page: "signup" };

        } else if (pathSplit[0] === 'guides') {

            return { page: "guides" }

        } else if (pathSplit[0] === 'home') {

            return { page: "home" }

        } else if (pathSplit[0] === "") {

            return { page: "login" };

        } else {

            return { page: "view", guideId: pathSplit[0] };

        }

    } else if (pathSplit.length > 1) {

        if (pathSplit[1] === "edit") {

            return { page: "edit", guideId: pathSplit[0] }

        } else if (pathSplit[1] === 'present') {

            return { page: "present", guideId: pathSplit[0] }

        } else if (pathSplit[1] === 'view') {

            return { page: 'view', guideId: pathSplit[0] }

        } else {

            return { page: "view", guideId: pathSplit[0] }

        }
    }
}

export default pathNameParse