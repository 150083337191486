import getFirestoreDb from "./getFirestoreDb";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

const addSessionToContact = (userId, contactId, sessionId) => {
    
    const db = getFirestoreDb();
    const contactDoc = doc(db, 'users/' + userId + '/contacts/' + contactId);
    updateDoc(contactDoc, {
        sessions: arrayUnion(sessionId)
    });

    const sessionDoc = doc(db, 'users/' + userId + '/sessions/' + sessionId);
    updateDoc(sessionDoc, {
        participants: arrayUnion(contactId)
    });

}

export default addSessionToContact;