import React from 'react';
import { SVG } from './SVG';
import Play from './assets/play.svg';
import Stop from './assets/stop.svg';
import './PresentationActionButton.css';

export const PresentationActionButton = ({ state, onClick }) => {

    const getIcon = () => {
        switch (state) {
            case 'play':
                return Play;
            case 'stop':
                return Stop;
            default:
                return Play;
        }
    }

    const getTitle = () => {
        switch (state) {
            case 'play':
                return 'Press play to start';
            case 'stop':
                return 'You are live';
            default:
                return 'Press play to start';
        }
    }

    const getClass = () => {
        switch (state) {
            case 'play':
                return 'presentation-action-start-button';
            case 'stop':
                return 'presentation-action-stop-button';
            default:
                return 'presentation-action-start-button';
        }
    }

    return (
        <div className={`presentation-action ${getClass()}`}>
            <SVG className="presentation-action-button" src={getIcon()} width={64} onClick={onClick} />
            <p className="presentation-action-text">{getTitle()}</p>
        </div>
    )
}

export default PresentationActionButton;