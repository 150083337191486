import React from "react";
import "./HomeNavigation.css";
import { Link, Outlet } from "react-router-dom";
import AvatarInitials from "./AvatarInitials";

const HomeNavigation = ({fullName}) => {
    return (
        <div>
            <div>
                <Link to="/guides">Guides</Link>
                <Link to="/sessions">Sessions</Link>
                <Link to="/contacts">Contacts</Link>
                <Link to="/accounts">Accounts</Link>
                <Link to="/todos">To-do's</Link>
                <AvatarInitials fullName={fullName}/>
            </div>
            <Outlet />
        </div>
    );
}

export default HomeNavigation;