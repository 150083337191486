import React from "react";
//import Prototype from "./Prototype.js";
import pathNameParse from "./functions/pathNameParse.js";
import renderPages from "./functions/renderPages.js";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';


const App = ()  => {
  
  const isProduction = process.env.NODE_ENV === 'production';
  const page = renderPages(pathNameParse(window.location.pathname));

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      //loadingComponent={} //If not pass, nothing appears at the time of new version check.
    >
      {page}
    </CacheBuster>
  );
}

export default App;
