import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";

const createContact = (userId, email, emailHash, fullName, account) => {
    return new Promise(resolve => {
        const db = getFirestoreDb();
        const contactDoc = doc(db, "users/" + userId + "/contacts/" + emailHash);
        getDoc(contactDoc).then(docSnap => {
            if(!docSnap.exists()){

                const contactData = {
                    email: email,
                    fullName: fullName,
                    account: account,
                    sessions: [],
                }
                setDoc(contactDoc, contactData).then(() => {
                    const userDoc = doc(db, 'users/' + userId);
                    updateDoc(userDoc, {
                        contacts: arrayUnion(emailHash)
                    }).then(() => {
                        resolve(true);
                    });
                });

            } else {
                resolve(true);
            }
        });
    });
};

export default createContact;