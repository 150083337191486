import "./LoadingGroup.css";
import { Heading4 } from ".";
import Loading from './resources/loading_bricks_124px.svg';
import { SVG } from "./SVG";

export const LoadingGroup = (props) => {

  return (
    <div className="loading-group-container">
        <SVG src={Loading} />
        <Heading4 text={props.loadingMessage} className="loading-group-message" />
    </div>
  );
};

export default LoadingGroup;