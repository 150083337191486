import React from "react";
import "./GuidesNavigation.css";
import { Link } from "react-router-dom";

const GuidesNavigation = () => {
    return (
        <div>
            <Link to="myguides">My guides</Link>
            <Link to="shared">Shared guides</Link>
            <Link to="templates">Templates</Link>
            <Link to="archived">Archived guides</Link>
        </div>
    );
};

export default GuidesNavigation;