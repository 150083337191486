import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, } from "firebase/firestore";

const getUserContacts = userData => {
    return new Promise(resolve => {
        if (userData.contacts && userData.contacts.length > 0) {

            const db = getFirestoreDb();
            const contactsLength = userData.contacts.length;
            let contactsLoaded = 0;
            let contactsArray = [];
            
            userData.contacts.forEach(contactId => {    
                const contactDocPath = "users/" + userData.uid + "/contacts/" + contactId;
                const contactDocRef = doc(db, contactDocPath);
                getDoc(contactDocRef).then(docSnap => {

                    if(docSnap.exists()) {

                        contactsLoaded = contactsLoaded + 1;
                        contactsArray.push({
                            ...docSnap.data(),
                            contactId: contactId
                        });

                        if (contactsLoaded === contactsLength) {
                            resolve(contactsArray);
                        };
                        
                    };
                });

            });
        } else {
            resolve([]);
        }
    });
}

export default getUserContacts