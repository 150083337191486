import React, { useEffect, useState } from 'react'
import './CircleProgress.css';

const CircleProgress = ({ value, className }) => {
    const [leftDeg, setLeftDeg] = useState(180);
    const [rightDeg, setRightDeg] = useState(90);
    const [displayRightSide, setDisplayRightSide] = useState(false);
    const [pieStyle, setPieStyle] = useState({ clip: '' });
    const [halfCircleStyle, setHalfCircleStyle] = useState({ borderColor: '#2B7A78' });
    const [leftHalfCircleStyle, setLeftHalfCircleStyle] = useState({ borderColor: '' });

    useEffect(() => {
        if (value && value <= 50) {
            setDisplayRightSide(true);
            setLeftHalfCircleStyle({ borderColor: '#2B7A78' });
            setRightDeg(360);
            setHalfCircleStyle({ borderColor: '#DEF2F1' });
        }
        else {
            setDisplayRightSide(true);
            setPieStyle({ clip: 'rect(auto, auto, auto, auto)' });
            setRightDeg(180);
            setHalfCircleStyle({ borderColor: '#2B7A78' });
        }
        
        setLeftDeg(value * 3.6);
    }, [value]);

    return (
        <div className={`pie-wrapper style-2 ${className ?? ''}`}>
            <div className="pie" style={pieStyle}>
                <div className="left-side half-circle" style={{ ...leftHalfCircleStyle, transform: `rotate(${leftDeg}deg)` }}></div>
                <div className="right-side half-circle" style={{ ...halfCircleStyle, transform: `rotate(${rightDeg}deg)`, display: displayRightSide ? 'block' : 'none' }}></div>
            </div>
        </div>
    );
}

export default CircleProgress;