import './EditGroup.css';
import { EditHeader, Button, Frame, SVG, Heading6, Text } from ".";
import ImportPdf from './ImportPdf';
import Plus from "./assets/plus.svg";
import Paper from './assets/paper.svg';
import iFrame from './assets/iframe.svg';
import ShareDark from './assets/share_dark.svg';
import Close from './assets/close.svg';
import CloseButton from './assets/close-button.svg';
import NextButton from './assets/next-button.svg';
import ArrowLeft from './assets/arrow-left.svg';
import ArrowRight from './assets/arrow-right.svg';
import NewSlide from './assets/new-slide.svg';
import Import from './assets/import.svg';
import Trash from './assets/trash.svg';
import Copy from './assets/copy.svg';
import Pdf from './assets/pdf.svg';
import Loading from './resources/loading_brings_64px.svg';
import { Input } from '.';
import { CenterFrame } from './CenterFrame';
import { DragDropContext, Droppable,Draggable } from 'react-beautiful-dnd';
import Toast from './Toast';
import { Heading5 } from './Heading5';
import Checkbox from './Checkbox';

export const EditGroup = (props) => {
    console.log('EditGroup props');
    console.log(props);

    //Turn this into a function and test it
    if (!props.editAPI) {
        props = {
            ...props,
            editAPI: () => {
                return {
                    updatePageStatus: () => console.log('updatePageStatus called'),
                    selectSlideFileOnClick: () => console.log('selectSlideFileOnClick called'),
                    updateSlidePosition: () => console.log('updateSlidePosition called'),
                    slidesInputFileOnChange: () => console.log('slidesInputFileOnchange called'),
                    processPDFSlidesInput: () => console.log('processPDFSlidesInput called'),
                    deleteSlideOnClick: () => console.log('deleteSlideOnClick called'),
                    guideNameOnChange: () => console.log('guideNameOnChange called'),
                    saveGuideDataOnClick: () => console.log('saveGuideDataOnClick called'),
                    updateSlidesOrder: () => console.log('udpateSlidesOrder called')
                };
            }
        }
    };
    if (!props.fileInput) {props = {...props, fileInput: null}};
    if (!props.guideData) {
        props = {
            ...props, 
            guideData: {
                name: "Default guide name",
                slides: [],
            }
        }
    };
    if (!props.pageStatus) {props = {...props, pageStatus: 'editSlides'}};
    if (!props.slidePosition) {props = {...props, slidePosition: 0}};
    if (!props.updatedGuideData) { props = {
            ...props,
            updatedGuideData: {
                name: 'Update guide default name'
            }
        }
    };
    if (!props.userData) { props = {
            ...props,
            userData: {
                displayName: 'Default name',
                email: 'default@gmail.com'
            }
        }
    };

    let slideTypes = [
        <Button key="iFrame" img={iFrame} value="iFrame Embed" className="edit-select-slide-type" tone="light" onClick={() => {props.editAPI().updatePageStatus('createIframe')}} />,
        <Button key="slides" img={Paper} value="Slides Import" className="edit-select-slide-type" tone="light" onClick={() => {props.editAPI().updatePageStatus('importPDF')}} />,
        <Button key="link" img={ShareDark} value="Link" className="edit-select-slide-type" tone="light" onClick={() => {props.editAPI().updatePageStatus('createLink')}} />
    ];

    //Center pane

    const createSlideCenterPane = (
        //UPDATE THIS COMPONENT FOR THE SELECT A NEW SLIDE TYPE
        <div className="edit-center-pane-container">
            <SVG src={NewSlide}></SVG>
            <Text text="Create your new slide" className="edit-center-pane-text"></Text>
            <SVG src={NextButton} width="32" className="mr-auto edit-center-pane-button" />
        </div>
    );

    const editSlideCenterPane = (
        <div className="edit-center-pane-container">
            <CenterFrame slideData={props.guideData.slides[props.slidePosition]} className="edit-center-frame" />
            <div className="edit-navigation-container">
                <SVG src={ArrowLeft} onClick={() => props.editAPI().updateSlidePosition(props.slidePosition - 1)} width="24" className="edit-navigation-arrows" />
                <Heading6 text={"Slide " + (props.slidePosition + 1) + " of " + props.guideData.slides.length} />
                <SVG src={ArrowRight} onClick={() => props.editAPI().updateSlidePosition(props.slidePosition + 1)} width="24" className="edit-navigation-arrows" />
            </div>
        </div>
    );

    const getCenterPane = () => {
        if (
            props.pageStatus === 'createSlide' ||
            props.pageStatus === 'createIframe' ||
            props.pageStatus === "importPDF" ||
            props.pageStatus === 'importPDFselected' ||
            props.pageStatus === 'importingPDFslides' ||
            props.pageStatus === 'createLink' ||
            props.guideData.slides.length < 1
        ) {
            return createSlideCenterPane;
        } else if (props.pageStatus === "importPDFselectSlides") {
            return (
                <div className="selected-slides-container">
                    <div className="selected-slides-header">
                        <div className="selected-slides-dropzone">
                            <input type="file" name="slide input" id="slides_input_file" hidden="" accept=".pdf" />
                            <div className="selected-slides-dropzone-label">
                                <SVG src={Pdf} size={21} />
                                <Heading5 text="random.pdf" />
                            </div>
                        </div>

                        <div className="selected-slides-select-container">
                            <div className="selected-slides-select-header">
                                <Checkbox checked={false} />
                                <Heading5 text="Select all slides" />
                            </div>
                            <Heading5 text={`${props.pdfImportSelectedSlides.length} slides selected out of ${props.pdfImportSlides.length}`} />
                        </div>

                        <div className="flex-spacing"></div>

                        <div className="selected-slides-button-container">
                            <Button img={Import} value="Import Slides" onClick={() => console.log('import slides button click')} />
                        </div>
                    </div>

                    <div className="selected-slides-body">
                        {props.pdfImportSlides && props.pdfImportSlides.map((slide, index) => (
                            <div className={`selected-slides-slide${props.pdfImportSelectedSlides.includes(index) ? '-selected' : ''}`}>
                                {/* The image is ready to have an image url here */}
                                <img className="selected-slides-img" />
                            </div>
                        ))}
                    </div>
                </div>
            );
        } else {
            return editSlideCenterPane;
        }
    }

    let centerPane = getCenterPane();

    //Side pane

    const selectSlideType = (
        <div className="edit-details-pane-right">
            <SVG src={Close} width="32" className="mr-auto" onClick={() => props.editAPI().updatePageStatus("editSlides")}/>
            <input type="file" name="slide input" id="slides_input_file" hidden accept=".pdf" onChange={props.editAPI().slidesInputFileOnChange} />
            {slideTypes}
        </div>
    );

    const submitPDFSidePane = (
        <div className="edit-details-pane-right"> 
            <SVG src={Close} width="32" className="mr-auto" />
            <p>Slides selected: {props.fileInput ? props.fileInput.name : 'No slides selected'}</p>
            <Button value="Import slides" onClick={() => {props.editAPI().processPDFSlidesInput()}} />
        </div>
    );

    let sidePane = (() => {
        if (props.pageStatus === 'createSlide' && props.fileInput) {
            return submitPDFSidePane;
        } else if (props.pageStatus === 'createSlide' && !props.fileInput) {
            return selectSlideType;
        } else if (props.guideData.slides.length < 1 && props.pageStatus == 'editSlides') {
            return selectSlideType;
        } else if (props.pageStatus === 'editSlides') {
            const currentSlide = props.guideData.slides[props.slidePosition];
            const currentSlideType = currentSlide.type;

            if (currentSlideType === "pdf") {
                return (
                    <div className="edit-details-pane">
                        <div className="edit-details-pane-header">
                            <p className="edit-details-pane-header-text">PDF slide</p>
                        </div>
                        <div className="edit-details-pane-desc-container">
                        </div>
                        <Button className="edit-details-pane-desc-delete-button" img={Trash} value={"Delete slide"} onClick={() => {props.editAPI().updatePageStatus("deleteSlideConfirmation")}}></Button>
                    </div>
                );
            } else {
                return (
                    //UPDATE THIS COMPONENT TO SEE SLIDE DETAILS AND TO DELETE SLIDE
                    <div className="edit-details-pane">
                        <div className="edit-details-pane-header">
                            <p className="edit-details-pane-header-text">Slide details</p>
                        </div>
                        <div className="edit-details-pane-desc-container">
                            <div className="edit-details-pane-desc-row">
                                <p className="edit-details-pane-desc-title">URL</p>
                                <p className="edit-details-pane-desc-text">{currentSlide.url}</p>
                            </div>
                            <div className="edit-details-pane-desc-row">
                                <p className="edit-details-pane-desc-title">Title</p>
                                <p className="edit-details-pane-desc-text">{}</p>
                            </div>
                            <div className="edit-details-pane-desc-row">
                                <p className="edit-details-pane-desc-title">Description</p>
                                <p className="edit-details-pane-desc-text">{}</p>
                            </div>
                        </div>
                        <Button className="edit-details-pane-desc-delete-button" img={Trash} value={"Delete slide"} onClick={() => {props.editAPI().updatePageStatus("deleteSlideConfirmation")}}></Button>
                    </div>
                );
            }

        } else if (props.pageStatus === 'createIframe') {

            const invalidInput = () => {
                if (props.iFrameCreateValidUrlWarning) {
                    return (
                        <>
                            <div className="edit-details-pane-details-invalid-input-container">
                                <Input className="edit-details-pane-details-input" type={"text"} value={props.iFrameCreateSlideURL} onChange={props.editAPI().iFrameCreateSlideUrlUpdate} />
                            </div>
                            <Text text="Oops...looks like that's an invalid URL. Try again!" className="edit-details-pane-details-invalid-url-text"/>
                        </>
                    );
                } else {
                    return (
                        <div className="edit-details-pane-details-input-container">
                            <Input className="edit-details-pane-details-input" type={"text"} value={props.iFrameCreateSlideURL} onChange={props.editAPI().iFrameCreateSlideUrlUpdate} />
                        </div>
                    );
                }
            }
            const iFrameInput = invalidInput();

            return(
                <div className="edit-details-pane" >
                <div className="edit-details-pane-header">
                    <p className="edit-details-pane-header-text">Create iFrame slide</p>
                    <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => {props.editAPI().updatePageStatus('createSlide')}} />
                </div>
                <div className="edit-details-pane-details-container">
                    <p className="edit-details-pane-details-title">URL:</p>
                    {iFrameInput}
                </div>
                <Button className="edit-details-pane-details-button" value={"Create slide"} onClick={props.editAPI().iFrameCreateSlideOnClick}></Button>
            </div>
            );

        } else if (props.pageStatus === 'createLink') {

            const invalidNameInput = () => {
                if (props.linkeCreateSlideNameWarning) {
                    return (
                        <>
                            <div className="edit-details-pane-details-invalid-input-container">
                                <Input className="edit-details-pane-details-input" type={"text"} value={props.linkCreateSlideName} onChange={props.editAPI().linkCreateSlideNameUpdate} />
                            </div>
                            <Text text="Looks like this link is missing a name. Give it a name to create the link." className="edit-details-pane-details-invalid-url-text"/>
                        </>
                    );
                } else {
                    return (
                        <div className="edit-details-pane-details-input-container">
                            <Input className="edit-details-pane-details-input" type={"text"} value={props.linkCreateSlideName} onChange={props.editAPI().linkCreateSlideNameUpdate} />
                            <Text text="" className="edit-details-pane-details-invalid-url-text"/>
                        </div>
                    );
                }
            }
            const nameInput = invalidNameInput();

            const invalidInput = () => {
                if (props.linkCreateValidUrlWarning) {
                    return (
                        <>
                            <div className="edit-details-pane-details-invalid-input-container">
                                <Input className="edit-details-pane-details-input" type={"text"} value={props.linkCreateSlideURL} onChange={props.editAPI().linkCreateSlideUrlUpdate} />
                            </div>
                            <Text text="Oops...looks like that's an invalid URL. Try again!" className="edit-details-pane-details-invalid-url-text"/>
                        </>
                    );
                } else {
                    return (
                        <div className="edit-details-pane-details-input-container">
                            <Input className="edit-details-pane-details-input" type={"text"} value={props.linkCreateSlideURL} onChange={props.editAPI().linkCreateSlideUrlUpdate} />
                            <Text text="" className="edit-details-pane-details-invalid-url-text"/>
                        </div>
                    );
                }
            }
            const linkInput = invalidInput();

            return(
                <div className="edit-details-pane" >
                <div className="edit-details-pane-header">
                    <p className="edit-details-pane-header-text">Create Link slide</p>
                    <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => {props.editAPI().updatePageStatus('createSlide')}} />
                </div>
                <div className="edit-details-pane-details-container">
                    <p className="edit-details-pane-details-title">Name:</p>
                    {nameInput}
                    <p className="edit-details-pane-details-title"></p>
                    <p className="edit-details-pane-details-title">URL:</p>
                    {linkInput}
                </div>
                <Button className="edit-details-pane-details-button" value={"Create slide"} onClick={props.editAPI().linkCreateSlideOnClick}></Button>
            </div>
            );
        } else if (props.pageStatus === 'shareGuide') {
            return (
                //UPDATE THIS COMPONENT FOR SHARING PANE
                <div className="edit-details-pane">
                    <div className="edit-details-pane-header">
                        <p className="edit-details-pane-header-text">Sharing link</p>
                        <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => {props.editAPI().updatePageStatus('editSlides')}} />
                    </div>
                    <div className="edit-details-pane-sharing-container">
                        <div className="edit-details-pane-sharing-link-container">
                            {/* TODO: provide a sharing URL here */}
                            <p className="edit-details-pane-sharing-link">{"https://app.slide.guide/" + props.guideData.guideId}</p>
                        </div>
                        <Button img={Copy} value={"Copy link"} tone="light" onClick={() => {props.editAPI().saveGuideLinkToClipboard()}}/>
                    </div>
                </div>
            );
        } else if (props.pageStatus === 'shareGuideCopied') {
            return (
                //UPDATE THIS COMPONENT FOR SHARING PANE
                <div className="edit-details-pane">
                    <div className="edit-details-pane-header">
                        <p className="edit-details-pane-header-text">Sharing link</p>
                        <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => {props.editAPI().updatePageStatus('editSlides')}} />
                    </div>
                    <div className="edit-details-pane-sharing-container">
                        <div className="edit-details-pane-sharing-link-container">
                            {/* TODO: provide a sharing URL here */}
                            <p className="edit-details-pane-sharing-link">{"https://app.slide.guide/" + props.guideData.guideId}</p>
                        </div>
                        <Button img={Copy} value={"Copy link"} tone="light" onClick={() => {props.editAPI().saveGuideLinkToClipboard()}}/>
                    </div>
                    <Toast text="Link copied to clipboard" />
                </div>
            );
        } else if (props.pageStatus === 'importPDF' && props.fileInput === null) {
            return (
                <div className="edit-details-pane" >
                    <div className="edit-details-pane-header">
                        <p className="edit-details-pane-header-text">Import Slides</p>
                        <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => {props.editAPI().updatePageStatus('editSlides')}} />
                    </div>
                    <ImportPdf  fileInput={props.fileInput} selectSlideFileOnClick={props.editAPI().selectSlideFileOnClick} slidesInputFileOnChange={props.editAPI().slidesInputFileOnChange} />
                    <a href="#" className="edit-details-pane-link">Help me import slides?</a>
                </div>
            );
        } else if (props.fileInput !== null && props.pageStatus === 'importPDF') {
            return (
                //UPDATE THIS COMPONENT FOR THE IMPORT PDF SELECTED PANE
                <div className="edit-details-pane" >
                    <div className="edit-details-pane-header">
                        <p className="edit-details-pane-header-text">Import Slides</p>
                        <SVG className="m-0" src={CloseButton} width="21" onClick={() => { console.log('Import slides close') }} />
                    </div>
                    <ImportPdf fileInput={props.fileInput} selectSlideFileOnClick={props.editAPI().selectSlideFileOnClick} slidesInputFileOnChange={props.editAPI().slidesInputFileOnChange}/>
                    <Button value="Import Slides" img={Import} className="edit-details-pane-import-button" onClick={props.editAPI().processPDFSlidesInput} />
                </div>
            );
        } else if (props.pageStatus === 'importingPDFslides') {
            return (
                //UPDATE THIS COMPONENT FOR THE IMPORTING PDF SLIDES STATE
                <div className="edit-details-pane">
                    <div className="edit-details-pane-importing-container">
                        <SVG src={Loading} width="64px" className="m-0" />
                        <p className="edit-details-pane-importing-text">Importing slides...</p>
                    </div>
                </div>
            );
        } else if (props.pageStatus === 'deleteSlideConfirmation') {
            return (
                //UPDATE THIS COMPONENT FOR THE DELETE SLIDE CONFIRMATION
                <div className="edit-details-pane" >
                    <div className="edit-details-pane-header">
                        {/* Left empty to display the close button */}
                        <p className="edit-details-pane-header-text"></p>
                        <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => {props.editAPI().updatePageStatus("editSlides")}} />
                    </div>
                    <div className="edit-details-pane-delete-container">
                        <h3 className="edit-details-pane-delete-title">Are you sure you want to delete this slide?</h3>
                        <h3 className="edit-details-pane-delete-text">This action cannot be undo</h3>
                        <Button className="edit-details-pane-delete-button" img={Trash} value={"Yes Delete"} onClick={props.editAPI().deleteSlideOnClick}></Button>
                        <Button value={"I Changed My Mind"} onClick={() => {props.editAPI().updatePageStatus("editSlides")}} tone="light"></Button>
                    </div>
                </div>
            );
        } else if (props.pageStatus === 'editGuideDetails') {
            return (
                //UPDATE THIS COMPONENT FOR THE EDIT GUIDE DETAILS PAGE
                <div className="edit-details-pane" >
                    <div className="edit-details-pane-header">
                        <p className="edit-details-pane-header-text">Guide details</p>
                        <SVG className="m-0 edit-details-pane-header-button" src={CloseButton} width="21" onClick={() => { console.log('Import slides close') }} />
                    </div>
                    <div className="edit-details-pane-details-container">
                        <p className="edit-details-pane-details-title">Guide name</p>
                        <div className="edit-details-pane-details-input-container">
                            <Input className="edit-details-pane-details-input" type={"text"} value={props.updatedGuideData.name} onChange={props.editAPI().guideNameOnChange} />
                        </div>
                    </div>
                    <Button className="edit-details-pane-details-button" value={"Save"} onClick={props.editAPI().saveGuideDataOnClick}></Button>
                </div>
            );
        }
    })();

    //Edit iframe slide has input url, url validation with save option. Static data includes name, title, description, and image.

    return (
        <div>
            <EditHeader updatePageStatus={props.editAPI().updatePageStatus} guideName={props.guideData.name} guideId={props.guideData.guideId} userData={props.userData} />
            <div className="edit-area-container">
                <div className="edit-slide-pane-container">
                    <Button value="Slide" img={Plus} onClick={() => {props.editAPI().updatePageStatus('createSlide')}} />
                    <DragDropContext onDragEnd={props.editAPI().updateSlidesOrder}>
                        <Droppable droppableId="droppable-1">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} className="edit-slide-list-container">
                                {props.guideData.slides.map((slide, index) => {
                                    const frameClassNames = (index === props.slidePosition ? "edit-slide-frame": "edit-slide-frame mt-2");
                                    let SVGIcon;
                                    let pdf;
                                    if (slide.type === 'iFrame') {
                                        SVGIcon = iFrame;
                                    } else if (slide.type === 'link') {
                                        SVGIcon = ShareDark;
                                    } else if (slide.type === 'pdf') {
                                        SVGIcon = Paper;
                                        pdf = slide.url;
                                    }
                                    let img;
                                    if (slide.image && slide.image.url) {
                                        img = slide.image.url
                                    };
                                    let text;
                                    if (slide.showText) {
                                        text = slide.title;
                                    }
                                    return (
                                        <Draggable key={slide.slideId} draggableId={slide.slideId} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Frame 
                                                        key={index}
                                                        slideIndex={index}
                                                        className={frameClassNames} 
                                                        SVGIcon={SVGIcon} 
                                                        onClick={() => props.editAPI().updateSlidePosition(index)} 
                                                        img={img}
                                                        pdf={pdf}
                                                        showText={slide.showText}
                                                        showImg={slide.showImg}
                                                        showURL={slide.showURL}
                                                        title={slide.title}
                                                        description={slide.description}
                                                        url={slide.url}
                                                        showLink={slide.showLink}
                                                        linkName={slide.linkName}
                                                        selected={index === props.slidePosition}
                                                        />
                                                </div>
                                            )}   
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                </div>
                {centerPane}
                {sidePane}
            </div>
        </div>
    );
};

export default EditGroup;


