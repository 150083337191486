import { Heading4 } from "./Heading4.js";
import "./TableHead.css";

export const TableHead = ({ columns }) => {

  return (
    <thead>
      <tr>
        {columns && columns.map((col, colIndex) => (
          <th key={colIndex} className={`table-head-item ${col.titleClassName ? col.titleClassName : ''}`}>
            {" "}
            <Heading4 text={col.title} />{" "}
          </th>
        ))}
      </tr>
    </thead>
  );
};
