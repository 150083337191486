import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";

import "./Table.css";

export const Table = ({ dataSource, columns, className, rowOnClick}) => {

  return (
    <table className={`table ${className ?? ''}`}>
      <TableHead columns={columns} />
      <TableBody dataSource={dataSource ? dataSource : []} columns={columns ? columns : []} rowOnClick={rowOnClick}/>
    </table>
  );
};
