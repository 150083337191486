import { Text } from "./Text.js";
import "./Divider.css";

export const Divider = ({value}) => {

  return (
    <div className="divider-box">
      <span className="divider-line">&nbsp;</span>
      <span className={'divider-text'}>{value}</span>
      <span className="divider-line">&nbsp;</span>
    </div>
  );
};