import getFirestoreDb from "./getFirestoreDb";
import { doc, getDoc, } from "firebase/firestore";

const getUserAccounts = userData => {
    return new Promise(resolve => {
        if (userData.accounts && userData.accounts.length > 0) {

            const db = getFirestoreDb();
            const accountsLength = userData.accounts.length;
            let accountsLoaded = 0;
            let accountsArray = [];
            
            userData.accounts.forEach(accountId => {    
                const accountDocPath = "users/" + userData.uid + "/accounts/" + accountId;
                const accountDocRef = doc(db, accountDocPath);
                getDoc(accountDocRef).then(docSnap => {

                    if(docSnap.exists()) {

                        accountsLoaded = accountsLoaded + 1;
                        accountsArray.push({
                            ...docSnap.data(),
                            accountId: accountId
                        });

                        if (accountsLoaded === accountsLength) {
                            resolve(accountsArray);
                        };
                        
                    };
                });

            });
        } else {
            resolve([]);
        }
    });
}

export default getUserAccounts;