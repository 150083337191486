import getFirestoreDb from "./getFirestoreDb";
import { doc, setDoc } from "firebase/firestore"; 

const createUser = (user) => {
    return new Promise(resolve => {

        const db = getFirestoreDb();
        const docRef = doc(db, "users", user.uid );
        const newUserDoc = {
            email: user.email,
            fullName: user.displayName,
            guides: [],
            sessions: [],
            todos: [],
            contacts: [],
            accounts: []
        };

        setDoc(docRef, newUserDoc).then(res => {
            resolve(newUserDoc);
        });
    });
}

export default createUser;