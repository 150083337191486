import getFirestoreDb from "./getFirestoreDb";
import getRealTimeDatabase from "./getRealTimeDatabase";
import { collection, addDoc, serverTimestamp, doc, updateDoc, arrayUnion } from "firebase/firestore"; 
import { ref, update } from "firebase/database";


const createNewSession = (userId, guideId) => {
    return new Promise(resolve => {
        const db = getFirestoreDb();
        const sessionCollection = collection(db, "users/" + userId + '/sessions');
        addDoc(sessionCollection, {
            host: userId,
            guideId: guideId,
            createdDate: serverTimestamp(),
            participants: []
        }).then(sessionRef => {
            const userDoc = doc(db, 'users/' + userId);
            updateDoc(userDoc, {
                sessions: arrayUnion(sessionRef.id)
            });
            const rtdb = getRealTimeDatabase();
            const sessRef = ref(rtdb, 'sessions/' + guideId + '/sessionState');
            update(sessRef, {
                sessionId: sessionRef.id
            });
            resolve(sessionRef.id);
        })
    });
};

export default createNewSession;