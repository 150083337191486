import "./GuidesHeader.css";
import { Logo } from "./Logo.js";
import { Text } from "./Text.js";

export const GuidesHeader = (props) => {

  return (
    <div className="guides-header">
      <div className="guides-header-container">
        <div className="guides-header-flex-container">
          <Logo type={"dark"} />
          <div className="guides-header-text-conatiner">
            <Text text={props.displayName} className="guidse-header-display-name" />
            <Text text={props.email} className="guides-header-email" />
          </div>
        </div>
      </div>
    </div>
  );
};
