import { Heading5 } from "./Heading5";
import { Text } from "./Text.js";
import "./TableBody.css";

const RenderedChild = ({ data, column }) => {
  const el = column.render(data[column.key] ? data[column.key] : '', data);
  return el;
}

export const TableBody = ({ dataSource, columns, rowOnClick }) => {

  console.log(dataSource);
  console.log(columns);
  console.log(rowOnClick);

  //if coluimns[0].title === 'To Do' then pass down updatePage status to todo details 

  console.log(columns[0].title === 'To Do');

  return (
    <tbody>
      {dataSource.map((dt, idx) => (
        <tr key={idx} className="table-body-row">
          {columns.map((c) => {
            return (
              <td key={c.key} className={`table-body-items ${c.cellClassName ?? ''}`} onClick={() => { rowOnClick(dt, idx) }}>
                <RenderedChild data={dt} column={c} />
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};

