import { useEffect, useState } from 'react';
import { SVG } from '.';
import './Frame.css';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export const Frame = ({ slideIndex, SVGIcon, className, onClick, img, pdf, showText, showImg, title, description, showURL, url, linkName, showLink, selected }) => {
    // Verify if it should show a PDF in small or medium format according to the screen size.
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 1200px)").matches
    )
    
    // Event listener for screen size change, update matches if required
    useEffect(() => {
        window
            .matchMedia("(max-width: 1200px)")
            .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    //UPDATE all of the frames to render the SVG icon and the slide number outside of the frame
    if (SVGIcon && showLink && url && linkName) {
        return (
            <div className={`frame-container ${className ? className : ''}`}>
                <div className="frame-header-container">
                    <SVG src={SVGIcon} width="20" className="frame-svg" />
                    <span>{(slideIndex + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className={`frame frame-text ${selected ? 'frame-active' : ''}`} onClick={onClick}>
                    <h4 className="frame-text-title">{linkName}</h4>
                    <p className="frame-text-description">{url}</p>
                </div>
            </div>
        );
    } else if (SVGIcon && showURL && url) {
        return (
            <div className={`frame-container ${className ? className : ''}`}>
                <div className="frame-header-container">
                    <SVG src={SVGIcon} width="20" className="frame-svg" />
                    <span>{(slideIndex + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className={`frame frame-text ${selected ? 'frame-active' : ''}`} onClick={onClick}>
                    <h4 className="frame-text-title">{url}</h4>
                </div>
            </div>
        );
    } else if (SVGIcon && !img && !pdf) {
        return (
            <div className={`frame-container ${className ? className : ''}`}>
                <div className="frame-header-container">
                    <SVG src={SVGIcon} width="20" className="frame-svg" />
                    <span>{(slideIndex + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className={`frame ${selected ? 'frame-active' : ''}`} onClick={onClick}>
                </div>
            </div>
        );
    } else if (SVGIcon && img && !showText) {

        return (
            <div className={`frame-container ${className ? className : ''}`}>
                <div className="frame-header-container">
                    <SVG src={SVGIcon} width="20" className="frame-svg" />
                    <span>{(slideIndex + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className={`frame ${selected ? 'frame-active' : ''}`} onClick={onClick}>
                    <img src={img} alt="" className="frame-image-style" />
                </div>
            </div>
        );
    } else if (SVGIcon && pdf) {

        return (
            <div className={`frame-container ${className ? className : ''}`}>
                <div className="frame-header-container">
                    <SVG src={SVGIcon} width="20" className="frame-svg" />
                    <span>{(slideIndex + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className={`frame ${selected ? 'frame-active' : ''}`} onClick={onClick}>
                    <Document file={pdf} onLoadSuccess={() => { console.log('PDF loaded') }} loading={<div></div>}>
                        <Page pageNumber={1} renderMode="svg" width={250}  border-radius="8px" loading={<div></div>}>

                        </Page>
                    </Document>
                </div >
            </div>
        );
    } else if (SVGIcon && showText && !showImg) {

        return (
            <div className={`frame-container ${className ? className : ''}`}>
                <div className="frame-header-container">
                    <SVG src={SVGIcon} width="20" className="frame-svg" />
                    <span>{(slideIndex + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className={`frame frame-text ${selected ? 'frame-active' : ''}`} onClick={onClick}>
                    <h4 className="frame-text-title">{title}</h4>
                    <p className="frame-text-description">{description}</p>
                </div>
            </div>
        );
    } else if (SVGIcon && showText && showImg) {
 
        return (
            <div className={`frame-container ${className ? className : ''}`}>
                <div className="frame-header-container">
                    <SVG src={SVGIcon} width="20" className="frame-svg" />
                    <span>{(slideIndex + 1).toString().padStart(2, '0')}</span>
                </div>
                <div className={`frame ${selected ? 'frame-active' : ''}`} onClick={onClick}>
                    <img className="frame-image" src={img}></img>
                    <div className="frame-image-overlay"></div>
                    <div className="frame-image-text-container">
                        <h4 className="frame-image-text-title">{title}</h4>
                        <p className="frame-image-text-description">{description}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (<div className={`frame-container ${className ? className : ''}`} onClick={onClick}>
            <div cclassName={`frame ${selected ? 'frame-active' : ''}`}></div>
        </div>);
    };
};