import React from 'react'
import './GuidesSectionHeader.css';
import { Heading3 } from './Heading3';

const GuidesSectionHeader = ({ className, active, headings, onTabClick }) => {
  return (
    <div className={`guides-section-header-container ${className ? className : ''}`}>
      {headings && headings.map(heading => (
        <Heading3 key={heading.value} text={heading.label} className={`guides-section-heading${active === heading.value ? '-active' : ''}`} onClick={() => onTabClick(heading.value)} />
      ))}
    </div>
  )
}

export default GuidesSectionHeader;